/* Commerce Utilities for connecting to Magento GraphQL & Rest Services */
/* eslint-disable-next-line no-unused-vars */
const PasswordUtils = (() => {

	const _MIN_CLASSES = 3;
	const _MAX_CLASSES = 4;
	const _NO_PASSWORD = 0;
	const _WEAK_PASSWORD = 1;
	const _MEDIUM_PASSWORD = 2;
	const _STRONG_PASSWORD = 3;
	const _VERY_STRONG_PASSWORD = 4;
	

	// private method(s)
	const _constructor = () => {
		
	};

	const _checkPasswordStrength = (password) => {

		let result = {
			length: password.length,
			classes: _getCharsClasses(password),
			strength: ""
		}

		if(password.length === 0){
			result.strength = _NO_PASSWORD;
		}
		else if(password.length >= 1 && password.length < 8){
			result.strength = _WEAK_PASSWORD;

		}
		else if(password.length >= 8 && password.length < 12){

			if (result.classes == _MIN_CLASSES) {
				result.strength = _MEDIUM_PASSWORD;
			} else if (result.classes == _MAX_CLASSES) {
				result.strength = _STRONG_PASSWORD;
			} else {
				result.strength = _WEAK_PASSWORD;
			}

		}
		else{
			if (result.classes == _MIN_CLASSES) {
				result.strength = _STRONG_PASSWORD;
			} else if (result.classes == _MAX_CLASSES) {
				result.strength = _VERY_STRONG_PASSWORD;
			}
			else {
				result.strength = _WEAK_PASSWORD;
			}
		}

		return result;
	}

	const _getCharsClasses = (password) => {
		let classes = 0;

		const specialCharsRegex = /(@|%|\+|\/|'|!|#|\$|\^|\?|:|\.|\(|\)|\{|\}|\[|\]|~|,|_)/g;
		const lowerCaseCharsRegex = /[a-z]/g;
		const upperCaseCharsRegex = /[A-Z]/g;
		const digitsCharsRegex = /\d/g;
		
		if (password.match(specialCharsRegex)) {
			classes += 1;
		}

		if (password.match(lowerCaseCharsRegex)) {
			classes += 1;
		}

		if (password.match(upperCaseCharsRegex)) {
			classes += 1;
		}

		if (password.match(digitsCharsRegex)) {
			classes += 1;
		}

		return classes;
	}

	_constructor();

	// output/public
	return {
		checkPasswordStrength: _checkPasswordStrength,
		NO_PASSWORD: _NO_PASSWORD,
		WEAK_PASSWORD: _WEAK_PASSWORD,
		MEDIUM_PASSWORD: _MEDIUM_PASSWORD,
		STRONG_PASSWORD: _STRONG_PASSWORD,
		VERY_STRONG_PASSWORD: _VERY_STRONG_PASSWORD
	};
})();
