// ------------------ Modal (static)
/* eslint-disable-next-line no-unused-vars */
const Modal = (() => {
	// private var(s)

	// private method(s)
	const _constructor = () => {};

	// Bind Events in Modal
	const _bindModalEvents = ($el) => {
		// Find buttons that launch modal windows via anchor ID
		$.each($el.find('button.bat-modal-close'), (index, $button) => {
			$($button).click(() => {
				const targetId = $($button).data('parameters').replace('#', '');
				$(`#${targetId}`).removeClass('active').trigger('modal:close');
			});
		});

		// Event listeners
		$el.on('modal:open', () => {
			// console.log('modal open');
			/* eslint-disable-next-line no-undef */
			Utils.lockBody();
		});

		$el.on('modal:close', () => {
			// console.log('moda closed');
			/* eslint-disable-next-line no-undef */
			Utils.unlockBody();
		});
	};

	_constructor();

	// output/public
	return {
		bindModalEvents: _bindModalEvents,
	};
})();
