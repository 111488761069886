const JWTEncoder = (() => {

    const _createJwt = (data, key, consumerName, siteId) => {
        const payload = JSON.stringify(data);
        const payloadEncoded = _base64_encode(payload);
        const hmac = _hash_hmac( payloadEncoded, key);
        const signature = {
            sub: consumerName,
            exp: (Math.floor(new Date().getTime() / 1000) + 3600),
            site_id: siteId,
            hmac: hmac
        };
        const token = _jwtEncode(signature, key);
        return token;
    };

    const _jwtEncode = (payload, key) => {
        const header = {typ: 'JWT', alg: 'HS256'};
        const segments = [];
        segments.push(_urlsafeB64Encode(JSON.stringify(header)));
        segments.push(_urlsafeB64Encode(JSON.stringify(payload)));
        const signingInput = segments.join('.');
        const signature = _hash_hmac(signingInput, key);
        segments.push(_urlsafeEncode(signature));
        return segments.join('.');
    };

    const _hash_hmac = (message, secret) => {
        const hash = CryptoJS.HmacSHA256(message, secret);
        return CryptoJS.enc.Base64.stringify(hash);
    };

    const _urlsafeB64Encode = (input) =>{
        return _base64_encode(input).replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');
    }

    const _urlsafeEncode = (input) =>{
        return input.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');
    }

    const _base64_encode = (stringToEncode) => { // eslint-disable-line camelcase
        return btoa(stringToEncode);
    };

    return {
        createJwt: _createJwt
    }

})();