// ------------------ config (static)
/* eslint-disable-next-line no-unused-vars */
const CONFIG = (() => {
	// private var(s)
	const _googleMapsKey = typeof googleMapsAPIKey !== 'undefined' ? googleMapsAPIKey : '';

	// private method(s)
	const _constructor = () => {
		// console.log('Config is running');
	};

	_constructor();

	// output/public
	return {
		googleMapsKey: _googleMapsKey,
	};
})();
