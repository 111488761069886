// ------------------ analytics (static)
/* eslint-disable-next-line no-unused-vars */
const analytics = (() => {
	// private var(s)

	// Track Event
	/* eslint-disable-next-line no-unused-vars */
	const _trackEvent = (eventData) => {
		/* eslint-disable-next-line no-console */
		console.log('eventData', eventData);

		if (typeof dataLayer !== 'undefined') {
			dataLayer.push(eventData);
		}
	};

	// Bind Events
	const _bindEvents = () => {
		// Add to cart event
		/* eslint-disable-next-line no-unused-vars */
		// document.addEventListener('addItemToCart', (e) => {
		// 	_trackEvent(e);
		// });

		// Product View
		/* eslint-disable-next-line no-unused-vars */
		document.addEventListener('productView', (e) => {
			if (typeof dataLayer !== 'undefined') {
				dataLayer.push(e);
			}
		});

		// Log In
		/* eslint-disable-next-line no-unused-vars */
		/*document.addEventListener('logIn', (e) => {
			// _trackEvent('auth: log in', e.detail);
		});*/

		// Log Out
		/* eslint-disable-next-line no-unused-vars */
		/*document.addEventListener('logOut', (e) => {
			// _trackEvent('auth: log out', e.detail);
		});*/

		// Error
		/* eslint-disable-next-line no-unused-vars */
		/*document.addEventListener('error', (e) => {
			// _trackEvent('error', e.detail);
		});*/
	};

	// private method(s)
	const _constructor = async () => {
		// console.log('analytics is running');

		const { pathname } = new URL(window.location.href);
		const pagename = pathname.split('/').pop();

		// const subSections = pathname
		// 	.substring(0, pathname.lastIndexOf('/'))
		// 	.split('/')
		// 	.filter(Boolean);

		const loginToken = await checkLogin();

		// Determine if the current page is a product page
		let isProductPage =
			document.getElementsByTagName('bat-productlisting-gloit')
				.length > 0 ||
			document.getElementsByTagName('bat-producthero-gloit').length >
				0;

		!isProductPage && (isProductPage = null);

		if (typeof dataLayer !== 'undefined') {
			dataLayer.push({
				UserID: '',
				PageType: pagename,
				SystemType: isProductPage,
				LoggedInStatus: loginToken,
				Country: '',
				SiteSection: pagename,
			});
		}

		// let primeAcctNo = '';
		// let profileCity = '';
		// let profileState = '';
		// let profileZip = '';
		// if (loginToken) {
		// 	const credentials = await getAccessCredentials();
		// 	const profile = await getAccessProfile();
		// 	if (profile) {
		// 		profileCity = profile['ns0:Address'][0]['ns0:City'];
		// 		profileState = profile['ns0:Address'][0]['ns0:StateCode'];
		// 		profileZip = profile['ns0:Address'][0]['ns0:ZipCode'];
		// 	}
		// 	primeAcctNo = credentials.PrimeAcctNo;
		// }
		// window.digitalData = {
		// 	page: {
		// 		brand: 'velo',
		// 		pageName: document.title,
		// 		section: pagename,
		// 		subSection1: subSections[0] ? subSections[0] : '',
		// 		subSection2: subSections[1] ? subSections[1] : '',
		// 		subSection3: subSections[2] ? subSections[2] : '',
		// 		pageType: 'cms',
		// 		eCommAccess: '',
		// 	},
		// };
		// digitalData.visitor = {
		// 	userType: loginToken ? 'authenticated' : 'guest',
		// 	accountNo: primeAcctNo,
		// 	customerId: primeAcctNo,
		// 	city: profileCity,
		// 	state: profileState,
		// 	zip: profileZip,
		// 	data: '',
		// };
		// // Page Load
		// _trackEvent('page: track');
		// /* eslint-disable-next-line no-use-before-define */
		// _bindEvents();
	};

	_constructor();

	// output/public
	return {
		bindEvents: _bindEvents,
	};
})();
