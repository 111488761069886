window.bat = {};
bat.modelUrls = {};
bat.scriptUrls = {};
bat.styleUrls = {};
bat.templateUrls = {};

bat.loader = (() => {
	const _total = $(document).find('.bat').length;
	let _loaded = 0;

	const _check = () => {
		if (_total === _loaded) {
			$(document).trigger('BATComponentsLoaded');
		}
	};

	const _add = () => {
		_loaded += 1;
		_check();
	};
	return {
		add: _add,
	};
})();

bat.setup = () => {
	const $document = $(document);
	const $modelUrls = $document.find('.bat[data-model-url]');

	if ($modelUrls.length > 0) {
		// We're in the prototype and need to fetch the JSON data before firing the init

		// store urls to fetch in an array
		const urls = [];

		$modelUrls.each((x, el) => {
			urls.push({
				el,
				url: el.getAttribute('data-model-url'),
			});
		});

		// use map() to perform a fetch and handle the response for each url
		Promise.all(
			urls.map((cmp) =>
				fetch(cmp.url)
					.then((response) => {
						return response.text();
					})
					.then((data) => {
						$(cmp.el).attr('data-model', data);
						return data;
					})
					.catch((error) => {
						/* eslint-disable-next-line no-console */
						console.log(error);
					})
			)
		).then(() => {
			window.bat.init();
		});
	} else {
		// We're not in the prototype, fire the init
		bat.init();
	}
};

bat.init = () => {
	const $document = $(document);

	const allScripts = [];
	const allStyles = [];
	const allTemplates = [];
	const allLogiclessCustomElements = [];

	$document.find('.bat[data-logic-url]').each((x, el) => {
		allScripts.push(el.getAttribute('data-logic-url'));
	});

	$document.find('.bat[data-style-url]').each((x, el) => {
		allStyles.push(el.getAttribute('data-style-url'));
	});

	$document.find('.bat[data-template-url]').each((x, el) => {
		const isServerHbsEnabled = $(el).data('serverHbsEnabled');

		if (!isServerHbsEnabled) {
			allTemplates.push(el.getAttribute('data-template-url'));
		}
	});

	// Initialize any custom element that does not have a JS file as BATComponent
	$document
		.find('.bat')
		.not('.bat[data-logic-url]')
		.each((x, el) => {
			const tagName = $(el).prop('tagName');
			// Ensure this is a custom element
			if (tagName.includes('-')) {
				allLogiclessCustomElements.push(tagName.toLowerCase());
			}
		});

	const uniqueScripts = [...new Set(allScripts)];
	const uniqueStyles = [...new Set(allStyles)];
	const uniqueTemplates = [...new Set(allTemplates)];
	const uniqueLogiclessCustomElements = [
		...new Set(allLogiclessCustomElements),
	];

	uniqueScripts.forEach((url) => {
		bat.fetchScript(url, bat.addScript);
	});

	uniqueStyles.forEach((url) => {
		bat.fetchStyle(url, bat.addStyle);
	});

	uniqueTemplates.forEach((url) => {
		bat.fetchTemplate(url, bat.addTemplate);
	});

	uniqueLogiclessCustomElements.forEach((tagName) => {
		class uniqueLogiclessCustomElement extends BATComponent {}

		!customElements.get(tagName) &&
			customElements.define(tagName, uniqueLogiclessCustomElement);
	});

	$document.on('BATComponentsLoaded', () => {
		bat.watch();
	});
};

bat.fetchItem = (cache, url, callback) => {
	const comp = url;
	if (typeof cache[comp] !== 'undefined') {
		if (cache[comp].data.length === 0) {
			cache[comp].subs.push(callback);
		} else {
			callback(cache[comp].data);
		}
	} else {
		cache[comp] = {};
		cache[comp].data = '';
		cache[comp].subs = [];

		if (url) {
			fetch(url)
				.then((response) => {
					return response.text();
				})
				.then((data) => {
					cache[comp].data = data;
					callback(data);
					// Call any subbed functions which rely on same fetch call
					cache[comp].subs.forEach((sub) => {
						sub(data);
					});
				});
		}
	}
};

bat.fetchScript = (url, callback) => {
	bat.fetchItem(bat.scriptUrls, url, callback);
};

bat.addScript = (data) => {
	const script = document.createElement('script');
	script.innerHTML = data;
	document.body.appendChild(script);
};

bat.fetchStyle = (url, callback) => {
	bat.fetchItem(bat.styleUrls, url, callback);
};

bat.addStyle = (data) => {
	const style = document.createElement('style');
	style.innerHTML = data;
	document.head.appendChild(style);
};

bat.fetchTemplate = (url, callback) => {
	bat.fetchItem(bat.templateUrls, url, callback);
};

bat.addTemplate = (data) => {
	/* eslint-disable-next-line no-unused-vars */
	const template = data;
	// console.log('preloading template');
};

bat.watch = () => {
	// Create an observer instance linked to the callback function
	const observer = new MutationObserver((mutationsList) => {
		mutationsList.forEach((mutation) => {
			mutation.addedNodes.forEach((node) => {
				if ($(node).find('.bat').length) {
					$(node)
						.find('.bat[data-logic-url]')
						.each((count, value) => {
							const url = $(value).data('logicUrl');
							if (typeof bat.scriptUrls[url] === 'undefined') {
								bat.fetchScript(url, bat.addScript);
							}
						});
					$(node)
						.find('.bat[data-style-url]')
						.each((count, value) => {
							const url = $(value).data('styleUrl');
							if (typeof bat.styleUrls[url] === 'undefined') {
								bat.fetchStyle(url, bat.addStyle);
							}
						});

					$(node)
						.find('.bat[data-template-url]')
						.each((count, value) => {
							const isServerHbsEnabled = $(value).data(
								'serverHbsEnabled'
							);
							const url = $(value).data('templateUrl');

							if (
								typeof bat.templateUrls[url] === 'undefined' &&
								!isServerHbsEnabled
							) {
								bat.fetchTemplate(url, bat.addTemplate);
							}
						});
				}
			});
		});
	});

	const target = $(document).find('body > div.root')[0]
		? $(document).find('body > div.root')[0]
		: $(document).find('body')[0];

	observer.observe(target, {
		attributes: false,
		childList: true,
		subtree: true,
	});
};

$(() => {
	bat.setup();
});
