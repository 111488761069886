//Storage service
/* eslint-disable-next-line no-unused-vars */
/*const PRODUCT = "selected_product";
const PRODUCT_NAME = "product_name";
const IS_IMAGE = "is_image";
const IMAGE = "image";
const FRONT_TEXT = "front_text";
const IS_FRONT_PERSONALIZED = "is_front_personalized";
const FRONT_TEXT_DIRECTION = "front_text_direction";
const FRONT_FONT = "front_font";
const BACK_TEXT = "back_text";
const IS_BACK_PERSONALIZED = "is_back_personalized";
const BACK_TEXT_DIRECTION = "back_text_direction";
const BACK_FONT = "back_font";

const StorageService = (() => {

	// private method(s)
	const _constructor = () => {
		// console.log('Config is running');
	};

	const _setProductAttr = (key, value) => {
		
		var stringProduct = localStorage.getItem(PRODUCT);
		var product;
		if (!stringProduct) {
			product = {};
		} else {
			product = JSON.parse(stringProduct);
		}

		product[key] = value;
		localStorage.setItem(PRODUCT, JSON.stringify(product));
	}

	const _getProductAttr = (key) => {
		
		var stringProduct = localStorage.getItem(PRODUCT);

		var product;

		if (stringProduct) {
			product = JSON.parse(stringProduct);
		} 

		return product[key];
	}

	const _clearStorage = () => {
		localStorage.clear();
	}

	const _setProductName = (name) => {
		return _setProductAttr(PRODUCT_NAME, name);
	}
	
	const _getProductName = () => {
		return _getProductAttr(PRODUCT_NAME);
	}

	const _setIsImage = (isImmagine) => {
		return _setProductAttr(IS_IMAGE, isImmagine);
	}
	
	const _isImage = () => {
		return _getProductAttr(IS_IMAGE);
	}

	const _setImage = (image) => {
		return _setProductAttr(IMAGE, image);
	}
	
	const _getImage = () => {
		return _getProductAttr(IMAGE);
	}

	const _setFrontText = (frontText) => {
		return _setProductAttr(FRONT_TEXT, frontText);
	}
	
	const _getFrontText = () => {
		return _getProductAttr(FRONT_TEXT);
	}

	const _setIsFrontPersonalized = (isFrontPersonalized) => {
		return _setProductAttr(IS_FRONT_PERSONALIZED, isFrontPersonalized);
	}
	
	const _isFrontPersonalized = () => {
		return _getProductAttr(IS_FRONT_PERSONALIZED);
	}

	const _setFrontTextDirection = (frontTextDirection) => {
		return _setProductAttr(FRONT_TEXT_DIRECTION, frontTextDirection);
	}
	
	const _getFrontTextDirection = () => {
		return _getProductAttr(FRONT_TEXT_DIRECTION);
	}

	const _setFrontFont = (frontFont) => {
		return _setProductAttr(FRONT_FONT, frontFont);
	}
	
	const _getFrontFont = () => {
		return _getProductAttr(FRONT_FONT);
	}

	const _setBackText = (backText) => {
		return _setProductAttr(BACK_TEXT, backText);
	}
	
	const _getBackText = () => {
		return _getProductAttr(BACK_TEXT);
	}

	const _setIsBackPersonalized = (isBackPersonalized) => {
		return _setProductAttr(IS_BACK_PERSONALIZED, isBackPersonalized);
	}
	
	const _isBackPersonalized = () => {
		return _getProductAttr(IS_BACK_PERSONALIZED);
	}

	const _setBackTextDirection = (backTextDirection) => {
		return _setProductAttr(BACK_TEXT_DIRECTION, backTextDirection);
	}
	
	const _getBackTextDirection = () => {
		return _getProductAttr(BACK_TEXT_DIRECTION);
	}

	const _setBackFont = (backFont) => {
		return _setProductAttr(BACK_FONT, backFont);
	}
	
	const _getBackFont = () => {
		return _getProductAttr(BACK_FONT);
	}


	_constructor();

	// output/public
	return {
		setProductName: _setProductName,
		getProductName: _getProductName,
		setIsImmagine: _setIsImage,
		isImmagine: _isImage,
		setImage: _setImage,
		getImage: _getImage,
		setFrontText: _setFrontText,
		getFrontText: _getFrontText,
		setIsFrontPersonalized: _setIsFrontPersonalized,
		isFrontPersonalized: _isFrontPersonalized,
		setFrontTextDirection: _setFrontTextDirection,
		getFrontTextDirection: _getFrontTextDirection,
		setFrontFont: _setFrontFont,
		getFrontFont: _getFrontFont,
		setBackText: _setBackText,
		getBackText: _getBackText,
		setIsBackPersonalized: _setIsBackPersonalized,
		isBackPersonalized: _isBackPersonalized,
		setBackTextDirection: _setBackTextDirection,
		getBackTextDirection: _getBackTextDirection,
		setBackFont: _setBackFont,
		getBackFont: _getBackFont
	};
})();*/