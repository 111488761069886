// ------------------ widget (static)
/* eslint-disable-next-line no-unused-vars */
const WIDGET = (() => {
	// private var(s)

	// private method(s)
	const _constructor = () => {
		// console.log('Widget is running');

		/* eslint-disable-next-line no-use-before-define */
		_bindEvents();
	};

	// Bind Events
	const _bindEvents = () => {
		// console.log('Bind Events Here');

		// Modal Event listeners
		$('bat-section-modal, bat-video-default').on('modal:open', () => {
			const $body = $('body');
			$body.addClass('modal-open');
		});

		$('bat-section-modal, bat-video-default').on('modal:close', () => {
			const $body = $('body');
			$body.removeClass('modal-open');
		});
	};

	_constructor();

	// output/public
	return {
		bindEvents: _bindEvents,
	};
})();
