

const StoreLocatorUtils = (() => {
	


	// private method(s)
	const _constructor = () => {
		// console.log('Config is running');
	};

	

	const _getActivityDictionary = () => {
		const activity_dictionary={
			"Dispositivi":"activity_type",
			"NEO™ Stick":"activity_type2",
			"Distributore NEO™ Stick":"activity_type3",
			"Assistenza":"activity_type4"
		}
		return activity_dictionary;
	}

	const _getStoreDictionary = () => {
		const store_dictionary={
			"glo™ Agent":"glo™ Shop Agent",
			"NEO™ Availability":"Neo availability",
			"glo™ Shop":"glo™ Shop",
			"glo™ Popup Store":"glo™™™™ Discovery store"
		}
		return store_dictionary;
	}

	const _loadStores = async (componentObject) => {
		const jsonPath = componentObject.productImagePath;
	
		const request = await fetch(jsonPath)
			.then((response) => response.json())
			.then(async (formResponse) => {
				if (formResponse) {
					const { steps } = formResponse;
	
					if (steps && steps.length > 0) {
						// Loop steps
						await Promise.all(
							steps.map(async (step) => {
								const { formBlocks } = step;
	
								if (formBlocks && formBlocks.length > 0) {
									// Loop Rows
									await Promise.all(
										formBlocks.map(async (block, index) => {
											const { $ref } = block;
	
											if ($ref) {
												await _loadBlockJSON($ref).then(
													(blockResponse) => {
														formBlocks[
															index
														] = blockResponse;
													}
												);
											}
										})
									);
								}
							})
						);
					}
	
					componentObject.stores = formResponse;
				}
	
				return componentObject;
			})
			.catch((error) => {
				/* eslint-disable-next-line no-console */
				console.log('Request failed: ', error);
			});
	
		return componentObject.stores;
	};


	_constructor();

	// output/public
	return {
		loadStores: _loadStores,
		getActivityDictionary: _getActivityDictionary,
		getStoreDictionary: _getStoreDictionary
	};
})();