/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */

/*window.soaUrl =
	window.soaUrl ||
	`https://velo-dev01-cf.rjreynolds.hedy-agency-client.com/api`;

window.soaEnv = window.soaEnv || `qa`;
window.siteCode = window.siteCode || `VELO`;

const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCj3f6slz4L7GgQhQlKyLCVbmhu
z4zlFjJmn+pNtrBFcgoL/w+goQgU2xHIlXhw0dLddh2T1OJ4G/ilTg01CWbZITNY
uvEYgmZT71Io693J6EXNeZleC9dhTw/IwblXtaD60uvLG/tIL768upPCIk/9iT7k
GWdITJqXoA02pggZ+QIDAQAB
-----END PUBLIC KEY-----`;

const simpleLoginService = async ({ UserName, Password }) => {
	const crypt = new JSEncrypt();
	crypt.setPublicKey(PUBLIC_KEY);
	const req = await fetch(`${soaUrl}/simpleLogin`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify({
			UserName,
			Password: crypt.encrypt(Password),
		}),
	}).then((res) => res.json());
	return req;
};

const emailVerfiyService = async ({ EmailID }) => {
	const req = await fetch(`${soaUrl}/emailVerify`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify({
			EmailID,
		}),
	})
		.then((res) => res.json())
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const getCredentialsService = async ({ AccountNo, UserName }) => {
	const body = AccountNo
		? {
				AccountNo,
		  }
		: {
				UserName,
		  };
	const req = await fetch(`${soaUrl}/getCredentials`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify(body),
	})
		.then((res) => res.json())
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const getProfileService = async ({ AccountNo }) => {
	const req = await fetch(`${soaUrl}/getProfile`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: {
			AccountNo,
		},
	})
		.then((res) => res.json)
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const lookupProfileService = async ({
	FirstName,
	LastName,
	MiddleInitial,
	EmailAddress,
	DateOfBirth,
	Address,
}) => {
	const req = await fetch(`${soaUrl}/lookupProfile`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify({
			FirstName,
			LastName,
			MiddleInitial,
			EmailAddress,
			DateOfBirth,
			Address,
		}),
	})
		.then((res) => res.json())
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const getKBAQuestionsService = async ({
	FirstName,
	LastName,
	MiddleInitial,
	EmailAddress,
	DateOfBirth,
	Age,
	Address,
}) => {
	const req = await fetch(`${soaUrl}/getKBAQuestions`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify({
			FirstName,
			LastName,
			MiddleInitial,
			EmailAddress,
			DateOfBirth,
			Age,
			Address,
		}),
	})
		.then((res) => res.json())
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const createProfileService = async ({
	FirstName,
	LastName,
	MiddleInitial,
	EmailAddress,
	DateOfBirth,
	Phone,
	AccountNo,
	LastFourDigitSSN,
	kbaVerificationFlag,
	Address,
	kbaRequest,
}) => {
	const req = await fetch(`${soaUrl}/createProfile`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify({
			FirstName,
			LastName,
			MiddleInitial,
			EmailAddress,
			DateOfBirth,
			Phone,
			AccountNo,
			LastFourDigitSSN,
			kbaVerificationFlag,
			Address,
			kbaRequest,
		}),
	})
		.then((res) => res.json())
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const createCredentialsService = async ({
	Email,
	Password,
	ChallengeQuestion,
	ChallengeAnswer,
	AccountNo,
}) => {
	const crypt = new JSEncrypt();
	crypt.setKey(PUBLIC_KEY);
	const req = await fetch(`${soaUrl}/createCredentials`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify({
			Email,
			Password: crypt.encrypt(Password),
			ChallengeQuestion,
			ChallengeAnswer: crypt.encrypt(ChallengeAnswer),
			AccountNo,
		}),
	})
		.then((res) => res.json())
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const updateCredentialsService = async ({
	Email,
	Password,
	ChallengeQuestion,
	ChallengeAnswer,
	AccountNo,
}) => {
	const crypt = new JSEncrypt();
	crypt.setKey(PUBLIC_KEY);
	const req = await fetch(`${soaUrl}/updateCredentials`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify({
			Email,
			Password: crypt.encrypt(Password),
			ChallengeQuestion,
			ChallengeAnswer: crypt.encrypt(ChallengeAnswer),
			AccountNo,
		}),
	})
		.then((res) => res.json)
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const sendEmailService = async ({
	EmailAddress,
	FirstName,
	LastName,
	EventType,
	AccountNo,
}) => {
	const req = await fetch(`${soaUrl}/commRequest`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify({
			EmailAddress,
			FirstName,
			LastName,
			EventType,
			AccountNo,
		}),
	})
		.then((res) => res.json())
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const getSurveyService = async ({ SurveyType, AccountNo }) => {
	const req = await fetch(`${soaUrl}/getSurvey`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify({
			SurveyType,
			AccountNo,
		}),
	})
		.then((res) => res.json())
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const updateSurveyResponseService = async ({ AccountNo, SurveyID, QA }) => {
	const req = await fetch(`${soaUrl}/updateSurveyResponse`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify({ AccountNo, SurveyID, QA }),
	})
		.then((res) => res.json())
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const validateEncryption = async ({ Text, Hash }) => {
	const req = await fetch(`${soaUrl}/validateEncryption`, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify({
			Text,
			Hash,
		}),
	})
		.then((res) => res.json())
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const verifyToken = async () => {
	const tokenReq = await getAccessCredentials();

	if (!tokenReq) return null;

	const req = await fetch(`${soaUrl}/verifyToken`, {
		method: 'POST',
		body: JSON.stringify({
			refreshToken: tokenReq.refreshToken,
		}),
	}).then((res) => res.json());
	if (req) {
		await putAccessCredentials(req);
	}
	return req;
};

const getSavings = async (query, endpoint) => {
	const req = await fetch(endpoint, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify(query),
	})
		.then((res) => res.json())
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};

const getOffers = async (query, endpoint) => {
	const req = await fetch(endpoint, {
		method: 'POST',
		headers: {
			env: soaEnv,
			sitecode: siteCode,
		},
		body: JSON.stringify(query),
	})
		.then((res) => res.json())
		.catch((err) => {
			throw new Error(err);
		});
	return req;
};*/
