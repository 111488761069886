/* eslint-disable no-unused-vars */

const DynamicFormBuilder = (
	formElement,
	attributes,
	formData,
	blockWrapperClass,
	inputWrapperClass,
	optionWrapperClass
) => {
	attributes &&
		Object.keys(attributes).forEach((key) => {
			formElement.setAttribute(key, attributes[key]);
		});

	function createLabel(item) {
		const labelElement = document.createElement('label');
		const labelKeys = Object.keys(item.label);
		labelKeys.forEach((key) => {
			labelElement.setAttribute(key, item.label[key]);
		});
		labelElement.innerText = item.label.innerText;
		return labelElement;
	}

	function createInputElement(item) {
		let element = null;
		let wrapper = null;
		let inputWrapper = null;

		switch (item.type) {
			case 'button': {
				element = document.createElement(item.type);
				const inputKeys = Object.keys(item.attributes);
				inputKeys.forEach((key) => {
					element.setAttribute(key, item.attributes[key]);
				});
				element.innerText = item.attributes.innerText;
				break;
			}
			case 'select': {
				element = document.createElement(item.type);
				const inputKeys = Object.keys(item.attributes);
				inputKeys.forEach((key) => {
					element.setAttribute(key, item.attributes[key]);
				});
				const defaultOption = document.createElement('option');
				defaultOption.value = null;
				defaultOption.innerText = 'Please Select One';
				defaultOption.selected = true;
				element.appendChild(defaultOption);
				item.options.forEach((option) => {
					const optionElement = document.createElement('option');
					optionElement.value = option.value;
					optionElement.innerText = option.innerText;
					element.appendChild(optionElement);
				});
				wrapper = document.createElement('div');
				wrapper.classList = 'select-wrapper';
				wrapper.appendChild(element);
				inputWrapper = document.createElement('div');
				inputWrapper.classList = inputWrapperClass;
				break;
			}
			case 'radioGroup': {
				wrapper = document.createElement('fieldset');
				wrapper.classList = optionWrapperClass;
				item.options.forEach((option) => {
					const labelElement = document.createElement('label');

					const radioElement = document.createElement('input');
					radioElement.type = 'radio';

					Object.keys(option).forEach((key) => {
						radioElement.setAttribute(key, option[key]);
					});

					const spanElement = document.createElement('span');
					spanElement.innerText = option.innerText;

					[radioElement, spanElement].forEach((e) => {
						labelElement.appendChild(e);
					});
					wrapper.appendChild(labelElement);
				});
				break;
			}
			case 'checkboxGroup': {
				wrapper = document.createElement('fieldset');
				wrapper.classList = optionWrapperClass;
				item.options.forEach((option) => {
					const labelElement = document.createElement('label');

					const checkBoxElement = document.createElement('input');
					checkBoxElement.type = 'checkbox';

					Object.keys(option).forEach((key) => {
						checkBoxElement.setAttribute(key, option[key]);
					});

					const spanElement = document.createElement('span');
					spanElement.innerText = option.innerText;

					[checkBoxElement, spanElement].forEach((e) => {
						labelElement.appendChild(e);
					});
					wrapper.appendChild(labelElement);
				});
				break;
			}
			default: {
				element = document.createElement(item.type);
				const inputKeys = Object.keys(item.attributes);
				inputKeys.forEach((key) => {
					element.setAttribute(key, item.attributes[key]);
				});
				inputWrapper = document.createElement('div');
				inputWrapper.classList = inputWrapperClass;
				break;
			}
		}

		if (inputWrapper) {
			inputWrapper.appendChild(wrapper || element);
			return inputWrapper;
		}

		return wrapper || element;
	}

	const dynamicFormWrapper = document.createElement('div');
	dynamicFormWrapper.classList = 'bat-dynamic-form';

	formData.forEach((item) => {
		const blockWrapper = document.createElement('div');
		blockWrapper.classList = blockWrapperClass;
		blockWrapper.style.flexDirection = 'column';

		const label = item.label && createLabel(item);
		label && blockWrapper.appendChild(label);

		const input = createInputElement(item);
		blockWrapper.appendChild(input);

		dynamicFormWrapper.appendChild(blockWrapper);
	});

	formElement.appendChild(dynamicFormWrapper);
};
