// ------------------ Waypoints (static)
/* eslint-disable-next-line no-unused-vars */
const Waypoints = (() => {
	// private var(s)

	// private method(s)
	const _constructor = () => {
		setTimeout(() => {
			/* eslint-disable-next-line no-use-before-define */
			_findWayPoints($('.bat-waypoint'), 'bat-waypoint--in-view');
		}, 2000);
	};

	const _addWayPoint = ($el, eventClass) => {
		/* eslint-disable-next-line no-unused-vars */
		const waypoint = new Waypoint.Inview({
			element: $el,
			enter: () => {
				$el.addClass(eventClass);
			},
			exited: () => {
				$el.removeClass(eventClass);
			},
		});
	};

	const _findWayPoints = (selector, eventClass) => {
		const $collection = $('body').find(selector);

		$collection.each((index, item) => {
			_addWayPoint($(item), eventClass);
		});
	};

	const _removeWayPoint = ($el) => {
		$el.waypoint('destroy');
	};

	const _clearWayPoints = (selector) => {
		const $collection = $('body').find(selector);

		$collection.each((index, item) => {
			_removeWayPoint($(item));
		});
	};

	_constructor();

	// output/public
	return {
		findWayPoints: _findWayPoints,
		clearWayPoints: _clearWayPoints,
	};
})();
