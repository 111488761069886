/* eslint-disable-next-line no-unused-vars */
class BATComponent extends HTMLElement {
	static get observedAttributes() {
		return ['data-model', 'data-style-url'];
	}

	constructor() {
		super();

		const isLoaded = this.classList.contains('loaded');
		const noUpdate = this.classList.contains('no-update');

		// No HBS after first render
		if (isLoaded && noUpdate) {
			$(this).data('serverHbsEnabled', true);
		}

		// State vars
		this.loaded = false;

		// Allow extending component to change state vars before finishing constructor with options()
		this.options();

		// Prepare JSON data
		if (this.dataset.model && this.dataset.model !== 'null') {
			this.data = JSON.parse(this.dataset.model);
		} else {
			this.data = {};
		}

		this.createSlotDOM(() => {
			// Global component setup tasks useful for most components
			this._setup();
		});
	}

	createSlotDOM(callback) {
		const isServerHbsEnabled = $(this).data('serverHbsEnabled');

		// Stop render
		if (isServerHbsEnabled === true) {
			callback();
			return;
		}

		this.data.slotHTML = `<div class="bat-slot-placeholder"></div>`;
		this.data.slotHTMLItems = this.children.length > 0;
		this.slotDOM = document.createDocumentFragment();

		Array.prototype.slice.call(this.children).forEach((child) => {
			this.slotDOM.appendChild(child);
		});

		callback();
	}

	options() {
		// For use in extending components
	}

	_setup() {
		const isAuthorMode = document.body.classList.contains('authorMode');

		// Add class for author mode
		if (isAuthorMode) {
			this.classList.add('author-mode');
		}

		// Background color
		if (
			typeof this.data.backgroundColor !== 'undefined' &&
			this.data.backgroundColor !== 'none' &&
			this.data.backgroundColor !== ''
		) {
			this.setAttribute(
				'style',
				`background-color:${this.data.backgroundColor}`
			);
		}

		// Hide/Show content
		if (this.data.hideShow) {
			const hs = this.data.hideShow;
			// Multiple calls to classList.add to support IE11
			this.classList.add(hs.xSmallVisibility);
			this.classList.add(hs.smallVisibility);
			this.classList.add(hs.mediumVisibility);
			this.classList.add(hs.largeVisibility);
			this.classList.add(hs.xLargeVisibility);
		}

		// Margins
		if (this.data.viewportBehaviour === 'same') {
			this.classList.add(this.data.sameSpacing.spacing);
		} else if (this.data.viewportBehaviour === 'different') {
			const sp = this.data.differentSpacing;
			// Multiple calls to classList.add to support IE11
			this.classList.add(sp.xSmallSpacing);
			this.classList.add(sp.smallSpacing);
			this.classList.add(sp.mediumSpacing);
			this.classList.add(sp.largeSpacing);
			this.classList.add(sp.xLargeSpacing);
		}

		// Waypoint
		if (this.data.waypoint && this.data.waypoint === 'true') {
			this.classList.add('bat-waypoint');
		}
	}

	beforeLoad() {
		// For use in extending components
	}

	connectedCallback() {
		// Extending component event callback
		this.beforeLoad();

		// Render HTML
		this.updateInnerHTML(() => {
			// Mark component as ready
			this.classList.add('ready');

			// Extending component event callback
			this.afterLoad();

			// Mark component as loaded for clone components
			this.classList.add('loaded');

			// Enable attributeChangedCallback
			this.loaded = true;

			// Track loading of component at a global level
			bat.loader.add();
		});
	}

	afterLoad() {
		// For use in extending components
	}

	beforeUpdate() {
		// For use in extending components
	}

	attributeChangedCallback() {
		if (!this.loaded) return;

		if (this.dataset.model) {
			this.data = JSON.parse(this.dataset.model);
		}

		this.beforeUpdate();
		this.updateInnerHTML(() => {
			this.afterUpdate();
		});
	}

	afterUpdate() {
		// For use in extending components
	}

	unload() {
		// For use in extending components
	}

	disconnectedCallback() {
		this.unload();
	}

	render(template) {
		if (template) {
			this.hbs = template;

			return Handlebars.compile(template)(this.data);
		}
		return '';
	}

	determineRenderer(markup) {
		const cqElementChildElements = this.slotDOM.querySelectorAll('cq')
			.length;
		const slotDomFragment =
			cqElementChildElements > 0
				? this.slotDOM
				: this.slotDOM.cloneNode(true);

		if (markup.length > 0) {
			if (this.getElementsByClassName('render').length) {
				this.getElementsByClassName('render')[0].innerHTML = markup;
			} else {
				this.innerHTML = markup;
				const slot = this.querySelectorAll('.bat-slot-placeholder')[0];

				if (slot) {
					slot.parentNode.insertBefore(slotDomFragment, slot);
					slot.parentNode.removeChild(slot);
				}
			}
		} else {
			this.appendChild(slotDomFragment);
		}
	}

	updateInnerHTML(callback) {
		const isServerHbsEnabled = $(this).data('serverHbsEnabled');

		// Stop render
		if (typeof this.data === 'undefined' || isServerHbsEnabled === true) {
			callback();
			return;
		}

		if (typeof this.dataset.templateUrl !== 'undefined') {
			bat.fetchTemplate(this.dataset.templateUrl, (data) => {
				this.determineRenderer(this.render(data));
				callback();
			});
		} else {
			this.determineRenderer(this.render());
			callback();
		}
	}
}
