/* Commerce Utilities for connecting to Magento GraphQL & Rest Services */
/* eslint-disable-next-line no-unused-vars */
const Commerce = (() => {
	let lastItemAdded = '';
	let lastActionTaken = '';
	let cartMessage = '';
	// let baseURL =
	// 	'https://www-eu-uat4-global-vuse.non-prod.marketing.bat.net/graphql';
	let baseURL = '';
	let storeID;

	const cartUpdate = new CustomEvent('cart-update');
	const mixedCart = new CustomEvent('mixed-cart');
	const cartAction = new CustomEvent('cart-action');
	const loginAction = new CustomEvent('login-action');

	// private method(s)
	const _constructor = () => {
		// Get Product Catalog
		/* eslint-disable-next-line no-use-before-define */
		// _getProductCatalog('velo').then((response) => {
		// 	/* eslint-disable-next-line no-console */
		// 	console.log('All Products', response);
		// });

		// Set authored graphql endpoint
		if (typeof graphql !== 'undefined' && graphql !== '') {
			baseURL = graphql;
		}

		/* eslint-disable-next-line no-use-before-define */
		_cartCookieCheck();

		window.addEventListener('commerce-interfaces-loaded', () => {
			/* eslint-disable-next-line no-use-before-define */
			if (baseURL) {
				_checkLogin();
			}
			
			/* eslint-disable-next-line no-use-before-define */
			_setSessionStorage('strength-lookup-table', []);
			window.dispatchEvent(new Event('strength-table-available'));
		});
	};

	// Check for cart cookie
	const _cartCookieCheck = () => {
		const cartCookie = document.cookie.match(
			`(^|;) ?commerce-cart-id=([^;]*)(;|$)`
		);
		const cartData = localStorage.getItem('commerce-cart-storage');

		// Clear local cart storage if no cart cookie is found.
		if (!cartCookie && cartData) {
			localStorage.removeItem('commerce-cart-storage');
		}
	};

	// Set Session Storage
	const _setSessionStorage = (id, value) => {
		// Put the object into storage
		sessionStorage.setItem(id, JSON.stringify(value));

		return true;
	};

	// Get Session Storage
	const _getSessionStorage = (id) => {
		const storageObject = sessionStorage.getItem(id);

		// Retrieve the object from storage
		return storageObject;
	};

	// Fetch GET Request
	const _makeGetRequest = async (query, variables) => {
		// Clean strings of new lines and unnecessary space
		const queryString = query.replace(/\s\s+/g, ' ');
		const variablesString = variables
			? variables.replace(/\s\s+/g, ' ')
			: '';

		const queryEncodeString = `?query=${encodeURIComponent(queryString)}`;
		const variablesEncodeString = `&variables=${encodeURIComponent(
			variablesString
		)}`;

		delete requestHeader.Authorization;
		const url =
			baseURL +
			queryEncodeString +
			(variables ? variablesEncodeString : '');

		const request = await fetch(url, {
			method: 'GET',
			headers: requestHeader,
		})
			.then((response) => response.json())
			.then((response) => {
				// Console error messages and return
				if (response.errors && response.errors.length) {
					response.errors.map((error) => {
						/* eslint-disable-next-line no-console */
						console.log('Response failed: ', error.message);
						return false;
					});
				}

				return response;
			})
			.catch((error) => {
				/* eslint-disable-next-line no-console */
				console.log('Request failed: ', error);
			});

		return request;
	};

	// Fetch POST Request
	const _makePostRequest = async (query) => {
		const authToken = Utils.getCookie('commerce-auth-token');

		if (authToken) {
			requestHeader.Authorization = `Bearer ${authToken}`;
		} else {
			delete requestHeader.Authorization;
		}

		const request = await fetch(baseURL, {
			method: 'POST',
			headers: requestHeader,
			body: query,
		})
			.then((response) => response.json())
			.then((response) => {
				// Console error messages and return
				if (response.errors && response.errors.length) {
					response.errors.map((error) => {
						/* eslint-disable-next-line no-console */
						console.log('Response failed: ', error.message);
						/* eslint-disable-next-line no-console */
						console.log(
							'Response DebugMessage: ',
							error.debugMessage
						);
						return false;
					});
				}

				return response;
			})
			.catch((error) => {
				/* eslint-disable-next-line no-console */
				console.log('Request failed: ', error);
				/* eslint-disable-next-line no-console */
				console.log('Response DebugMessage: ', error.debugMessage);
			});

		return request;
	};

	const _getProductAggregations = async (categoryId) => {
		const requestBody = `{
			products(filter: { category_id: { eq: "${categoryId}" } }, currentPage: 1, pageSize: 100) {
			items {
				categories {
					name
					id
					uid
				}
			}
			aggregations {
				attribute_code
				label
				count
				options {
				  count
				  label
				  value
				}
			  }
			  total_count
			  page_info {
				total_pages
			  }
			}
		  }`;

		const request = await _makeGetRequest(requestBody).then((response) => {
			return response;
		});

		return request;
	};

	const _getStrengthTable = async () => {
		const strengthLookupTable = _getSessionStorage('strength-lookup-table');
		const strengthTableAvailable = new Event('strength-table-available');

		if (strengthLookupTable && strengthLookupTable !== 'undefined') {
			window.dispatchEvent(strengthTableAvailable);
		} else {
			const requestBody = `{
				customAttributeMetadata(
					attributes: [{ attribute_code: "strength", entity_type: "catalog_product" }]
				) {
					items {
						attribute_code
						attribute_options {
							value
							label
						}
						entity_type
					}
				}
			}`;

			await _makeGetRequest(requestBody).then((response) => {
				const attributeOptions =
					response.data.customAttributeMetadata.items[0]
						.attribute_options;

				_setSessionStorage('strength-lookup-table', attributeOptions);

				window.dispatchEvent(strengthTableAvailable);

				return response;
			});
		}
	};

	const _getCategoryList = async (categoryId, customAttributes) => {
		const requestBody = `{
			${customAttributes
				? `
			customAttributeMetadata(
				attributes: [${customAttributes}]
			) {
				items {
					attribute_code
					attribute_type
					entity_type
					input_type
					attribute_options {
					value
					label
					}
				}
			},`
				: ``
			}
			categoryList (
				filters: {
					category_uid: { eq: "${categoryId}" }
				}
			) {
				${categoryListInterface}
			}
		}`;

		const request = await _makeGetRequest(requestBody).then((response) => {
			return response;
		});

		return request;
	};

	// Get Product Catalog
	/* eslint-disable-next-line no-unused-vars */
	const _getProductCatalog = async (
		categoryIds,
		filterProps,
		productSKU,
		currentPage,
		pageSize,
		sortParam,
		priceRange,
		searchString
	) => {
		createCategoriesString = (categories) => {
			let string = '';
			if (categories) {
				if (categories.length > 1) {
					categories.forEach((category) => {
						string += `"${category}",`;
					});
					return `
						category_id: { 
							in: [${string}] 
						}
					`;
				} else if (categories.length === 1) {
					string += `"${categories[0]}"`;
					return `
						category_id: { 
							eq: ${string} 
						}
					`;
				}
			}
			return string;
		};

		createPropertiesString = (properties) => {
			let string = '';
			if (properties) {
				const propertiesObject =
					!!properties &&
					properties.reduce((acc, cur) => {
						/* eslint-disable-next-line no-return-assign */
						return !acc[cur.filterProp]
							? {
								...acc,
								[cur.filterProp]: `"${cur.filterValue}",`,
							}
							: {
								...acc,
								[cur.filterProp]: (acc[
									cur.filterProp
								] += `"${cur.filterValue}",`),
							};
					}, {});
				Object.keys(propertiesObject).forEach((key) => {
					string += `${key}: { in: [${propertiesObject[key]}] }`;
				});
			}
			return string;
		};

		createSKUstring = (sku) => {
			let string = '';
			if (sku) {
				string = `sku: {eq: "${sku}"}`;
			}
			return string;
		};

		createPriceRangeString = () => {
			let string = '';
			if (priceRange) {
				string = `
					price: {
						from: "${priceRange.from}"
						to: "${priceRange.to}"
					}
				`;
			}
			return string;
		};

		createSearchString = () => {
			let string = '';
			if (searchString) {
				string = `search: "${searchString}"`;
			}
			return string;
		};

		const requestBody = `{
			products(
				filter: { 
					${createCategoriesString(categoryIds)}
					${createPropertiesString(filterProps)}
					${createSKUstring(productSKU)}
					${createPriceRangeString()}
				}
				${createSearchString()}
				sort: {
					${(!!sortParam && sortParam.sortProp) || 'position'}: ${(!!sortParam && sortParam.sortDir) || 'ASC'
			}
				}
				currentPage: ${currentPage}
				pageSize: ${pageSize}
			) {
				items 	{
					${productPropertiesInterface}
					${customProductPropertiesInterface}
					${configurableInterface}
					${bundleInterface}
				}
				total_count
				page_info {
					current_page
					page_size
					total_pages
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody).then((response) => {
			return response;
		});

		return request;
	};

	// Get Product Category List with uid
	/* eslint-disable-next-line no-unused-vars */
	const _getProductCategoryList = async (
		categoryIds,
		filterProps,
		productSKU,
		currentPage,
		pageSize,
		sortParam,
		priceRange,
		searchString
	) => {
		createCategoriesString = (categories) => {
			let string = '';

			if (categories) {
				categories.forEach((category) => {
					string += `"${category}",`;
				});
				return `
					category_uid: { 
						eq: ${string} 
					}
				`;
			}
			return string;
		};

		createPropertiesString = (properties) => {
			let string = '';
			if (properties) {
				const propertiesObject =
					!!properties &&
					properties.reduce((acc, cur) => {
						/* eslint-disable-next-line no-return-assign */
						return !acc[cur.filterProp]
							? {
								...acc,
								[cur.filterProp]: `"${cur.filterValue}",`,
							}
							: {
								...acc,
								[cur.filterProp]: (acc[
									cur.filterProp
								] += `"${cur.filterValue}",`),
							};
					}, {});
				Object.keys(propertiesObject).forEach((key) => {
					string += `${key}: { in: [${propertiesObject[key]}] }`;
				});
			}
			return string;
		};

		createSKUstring = (sku) => {
			let string = '';
			if (sku) {
				string = `sku: {eq: "${sku}"}`;
			}
			return string;
		};

		createPriceRangeString = () => {
			let string = '';
			if (priceRange) {
				string = `
					price: {
						from: "${priceRange.from}"
						to: "${priceRange.to}"
					}
				`;
			}
			return string;
		};

		createSearchString = () => {
			let string = '';
			if (searchString) {
				string = `search: "${searchString}"`;
			}
			return string;
		};

		const requestBody = `{
			categoryList(
			  filters: {
				${createCategoriesString(categoryIds)}
				${createPropertiesString(filterProps)}
				${createSKUstring(productSKU)}
				${createPriceRangeString()}
			  }
			)
			{
			  products(
				${createSearchString()}
				sort: {
					${(!!sortParam && sortParam.sortProp) || 'position'}: ${(!!sortParam && sortParam.sortDir) || 'DESC'
			}
				}
				currentPage: ${currentPage}
				pageSize: ${pageSize}
			  ) {
				items {
					${productPropertiesInterface}
					${customProductPropertiesInterface}
					${configurableInterface}
					${bundleInterface}
				}
				total_count
				page_info {
				  current_page
				  page_size
				  total_pages
				}
			  }
			}
		  }`;

		const request = await _makeGetRequest(requestBody).then((response) => {
			return response;
		});

		return request;
	};

	// Get One Product
	const _getProduct = async (productID) => {
		// Catch no productId.
		if (!productID) {
			return Promise.resolve('Need Product Id.');
		}

		const requestBody = `{
            products(
                filter: { sku: {eq: "${productID}"} }
            ) {
                items {
					${productPropertiesInterface}
					${customProductPropertiesInterface}
					${configurableInterface}
					${bundleInterface}
					${relatedProductsInterface}
                }
            }
			
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	};

	const _getProductUrlKey = async (productIDs) => {
		// Catch no productId.
		if (!productIDs) {
			return Promise.resolve('Need Product Ids.');
		}

		const requestBody = `{
            products(
                filter: { sku: {eq: "${productIDs}"} }
            ) {
                items {
					sku
					url_key
                }
            }
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	};

	/* Add Simple Product to Cart */
	const _addSimpleProduct = async (cartObject) => {
		// Catch no productId.
		if (!cartObject) {
			return Promise.resolve('Need Cart Object.');
		}

		const requestBody = `mutation addProductsToCart(
			$cartId: String!
			$sku: String!
			$quantity: Float!
		) {
			addProductsToCart(
				cartId: $cartId
				cartItems: [
					{ 
						quantity: $quantity, 
						sku: $sku 
						${cartObject.personalisation
				? `personalisation: {
									front_font: "${cartObject.front_font}"
									front_text: "${cartObject.front_text}"
									front_orientation: "${cartObject.front_orientation}"
									back_text: "${cartObject.back_text}"
									back_font: "${cartObject.back_font}"
									back_orientation: "${cartObject.back_orientation}"
									icon: ""
									pattern: "${cartObject.pattern}"
								}`
				: ''
			}
					}
				]
			) {
				cart {
					items {
						id
						product {
							id
							name
							sku
						}
						${cartObject.productDelivery
				? `subscription_data {
								is_subscription_item
								max_qty
								min_qty
								options {
								  label
								  value
								}
							}`
				: ''
			}
						${cartObject.personalisation
				? `personalisation {
									front_font
									front_text
									front_orientation
									back_text
									back_font
									back_orientation
									icon
									pattern
								}`
				: ''
			}
						quantity
					}
				}

				user_errors {
					code
					message
				}
			}
		}`;

		const variablesObj = {
			cartId: cartObject.cartId,
			sku: cartObject.productSku,
			quantity: cartObject.productQuantity,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		return request;
	};

	/* Add Virtual Product to Cart */
	const _addVirtualProduct = async (cartObject) => {
		// Catch no productId.
		if (!cartObject) {
			return Promise.resolve('Need Cart Object.');
		}

		const requestBody = `mutation addVirtualProductToCart(
			$cartId: String!
			$sku: String!
			$quantity: Float!
		) {
			addVirtualProductsToCart(
				input: {
					cart_id: $cartId
					cart_items: [
						{
							data: { 
								quantity: $quantity, 
								sku: $sku
							}
						}
					]
				}
			) {
				cart {
					items {
						id
						quantity
						product {
							id
							name
							sku
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId: cartObject.cartId,
			sku: cartObject.productSku,
			quantity: cartObject.productQuantity,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		return request;
	};

	/* Add Configurable Product to Cart */
	const _addConfigurableProduct = async (cartObject) => {
		// Catch no productId.
		if (!cartObject) {
			return Promise.resolve('Need Cart Object.');
		}

		const requestBody = `mutation addConfigurableProductToCart(
			$cartId: String!
			$parentSku: String!
			$sku: String!
			$quantity: Float!
		) {
			addConfigurableProductsToCart(
				input: {
					cart_id: $cartId
					cart_items: [
						{
							parent_sku: $parentSku,
							data: { 
								quantity: $quantity, 
								sku: $sku
								${cartObject.personalisation
				? `personalisation: {
											front_font: "${cartObject.front_font}"
	  										front_text: "${cartObject.front_text}"
											front_orientation: "${cartObject.front_orientation}"
											back_text: "${cartObject.back_text}"
											back_font: "${cartObject.back_font}"
											back_orientation: "${cartObject.back_orientation}"
											icon: ""
											pattern: "${cartObject.pattern}"
										}`
				: ''
			}
							}
						}
					]
				}
			) {
				cart {
					items {
						id
						quantity
						product {
							id
							name
							sku
						}
						${cartObject.productDelivery
				? `subscription_data {
								is_subscription_item
								max_qty
								min_qty
								options {
								  label
								  value
								}
							}`
				: ''
			}
						${cartObject.personalisation
				? `personalisation {
									front_font
									front_text
									front_orientation
									back_text
									back_font
									back_orientation
									icon
									pattern
								}`
				: ''
			}
						... on ConfigurableCartItem {
							configurable_options {
								option_label
							}
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId: cartObject.cartId,
			parentSku: cartObject.familySku,
			sku: cartObject.productSku,
			quantity: cartObject.productQuantity,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		return request;
	};

	/* Add Bundle Product to Cart */
	const _addBundleProduct = async (cartObject) => {
		// Catch no productId.
		if (!cartObject) {
			return Promise.resolve('Need Cart Object.');
		}

		const requestBody = `mutation addBundleProductToCart(
			$cartId: String!
			$sku: String!
			$quantity: Float!
			$bundleOptions: [BundleOptionInput]!
		) {
			addBundleProductsToCart(
				input: {
					cart_id: $cartId
					cart_items: [
						{
							data: { 
								sku: $sku, 
								quantity: $quantity 
							}
							bundle_options: $bundleOptions
						}
					]
				}
			) {
				cart {
					items {
						id
						quantity
						product {
							sku
						}
						... on BundleCartItem {
							bundle_options {
								id
								label
								type
								values {
									id
									label
									price
									quantity
								}
							}
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId: cartObject.cartId,
			sku: cartObject.productSku,
			quantity: cartObject.productQuantity,
			bundleOptions: cartObject.bundleOptions,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		return request;
	};

	/* Product Price Formatter */
	const _priceFormatter = (priceValue, priceType) => {
		let price = '';

		switch (priceType) {
			case 'USD':
				price = `$${priceValue}`;
				break;
			case 'GBP':
				price = `&#163;${priceValue}`;
				break;
			case 'EUR':
				price = `&#8364;${priceValue}`;
				break;
			case 'PLN':
				price = `${priceValue} &#122;&#322;`;
				break;
			case 'ZAR':
				price = `R ${priceValue}`;
				break;
			default:
				price = priceValue;
		}

		if (price) {
			return price.replace(".", ",");
		}
	};

	/* Get Cart */
	const _getCart = async (cartId) => {
		// Catch no cartId.
		if (!cartId) {
			return Promise.resolve('Need CartId.');
		}

		const requestBody = `query getCartDetails($cartId: String!) {
			cart(cart_id: $cartId) {
				email
				billing_address {
					city
					country {
						code
						label
					}
					firstname
					lastname
					postcode
					region {
						code
						label
					}
					street
					telephone
				}
				items {
					id
					product {
						${customProductPropertiesInterface}
						${productPropertiesInterface}
						... on ConfigurableProduct {
							variants {
								product {
									${productPropertiesInterface}
									${customProductPropertiesInterface}
								}
								attributes {
									label
									code
									uid
								}
							}
						}
						...on SimpleProduct {
							parent_sku
						}
					}
					... on ConfigurableCartItem {
						configurable_options {
							id
							option_label
							configurable_product_option_uid
							value_label
							value_id
							configurable_product_option_value_uid
						}
					}
					... on BundleCartItem {
						bundle_options {
							id
							label
							type
							values {
								id
								label
								price
								quantity
							}
						}
					}
					quantity
					prices {
						row_total_including_tax {
							value
							currency
						}
						personalisation_price {
							currency
							value
						}
						personalisation_tax {
							currency
							value
						}
					}
				}
				applied_coupons {
					code
				}
				prices {
					grand_total {
						value
						currency
					}
					personalisation_price {
						currency
						value
					}
					personalisation_tax {
						currency
						value
					}
				}
			}
		}`;

		const variablesObj = {
			cartId,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query).then(async (response) => {
			if (response.data.cart.items) {
				for (const item of response.data.cart.items) {
					const product = item.product;
					if (product && product.__typename === 'ConfigurableProduct') {
						for (const variant of product.variants) {
							variant.product.parent_url_key = product.url_key;
						}
					} else if (product && product.parent_sku) {
						const secondRequest = await _getProductUrlKey(product.parent_sku);
						if (secondRequest.data.products.items.length > 0) {
							const parentProduct = secondRequest.data.products.items[0];
							product.parent_url_key = parentProduct.url_key;
						}
					}
				}
			}
			// Add info on user action for messagebar
			response.lastItemAdded = lastItemAdded;
			response.lastActionTaken = lastActionTaken;
			response.cartMessage = cartMessage;

			Utils.setLocalStorage('commerce-cart-storage', response);

			// Trigger cart update window event
			window.dispatchEvent(cartUpdate);

			return response;
		});

		return request;
	};

	/* Set Cart Cookie */
	const _setCommerceCookie = (key, value, min) => {
		if (typeof store !== 'undefined') {
			const pathArray = store.split('_');
			storeID = `/${pathArray[2]}/${pathArray[3]}`;
		}

		if (window.location.hostname.indexOf('localhost') > -1) {
			storeID = '/';
		}

		const expires = new Date();
		expires.setTime(expires.getTime() + min * 60 * 1000);
		const isSecure = window.location.protocol === 'https:';
		const domain = document.domain.includes('.com')
			? `domain=.${document.domain.match(/[^.]*\.[^.]*$/)[0]};`
			: '';
		const cookieString = `${key}=${value};${isSecure ? 'secure;' : ''
			}samesite=strict;expires=${expires.toUTCString()};path=${storeID};${domain}`;
		document.cookie = cookieString;
	};

	/* Erase Cart Cookie */
	const _eraseCommerceCookie = (key) => {
		if (typeof store !== 'undefined') {
			const pathArray = store.split('_');
			storeID = `/${pathArray[2]}/${pathArray[3]}`;
		}

		if (window.location.hostname.indexOf('localhost') > -1) {
			storeID = '/';
		}

		const isSecure = window.location.protocol === 'https:';
		const domain = document.domain.includes('.com')
			? `domain=.${document.domain.match(/[^.]*\.[^.]*$/)[0]};`
			: '';
		const cookieString = `${key}=;${isSecure ? 'secure;' : ''
			}samesite=strict;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=${storeID};${domain}`;
		document.cookie = cookieString;
	};

	/* Create Empty Cart */
	const _createEmptyCart = async () => {
		const authToken = Utils.getCookie('commerce-auth-token');
		const requestBody = authToken
			? '{customerCart{id}}'
			: 'mutation {createEmptyCart}';

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query).then((response) => {
			const cartId = authToken
				? response.data.customerCart.id
				: response.data.createEmptyCart;

			_setCommerceCookie('commerce-cart-id', cartId, 20);
			_getCart(cartId);
			return cartId;
		});

		return request;
	};

	/* Set Auth Token */
	const _setAuthToken = async (email, password) => {
		const requestBody = `mutation {
			generateCustomerTokenSession(email: "${email}", password: "${password}") {
			  token
			}
		}`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query).then((response) => {
			const { errors } = response;

			if (!errors) {
				const authToken = response.data.generateCustomerTokenSession.token;
				_setCommerceCookie('commerce-auth-token', authToken, 60);
				sessionStorage.removeItem('customerData');
				localStorage.removeItem('loyaltyInfo');
				return { authToken };
			}
			return { errors };
		});

		return request;
	};

	const _changeCustomerPassword = async (currentPassword, newPassword) => {
		const requestBody = `mutation {
				changeCustomerPassword(
				  currentPassword: "${currentPassword}"
				  newPassword: "${newPassword}"
				) {
				  email
				}
		  }`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		console.log("Request",request)
		
		return request;
	};

	const _resetPassword = async (resetPasswordToken, newPassword) => {
		const requestBody = `mutation {
			resetPassword(
			  email: "",
			  resetPasswordToken: "${resetPasswordToken}",
			  newPassword: "${newPassword}"
			)
		  }`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		console.log(request);
		
		return request;
	};

	const _requestPasswordResetEmail = async (email) => {
		const requestBody = `mutation {
			requestPasswordResetEmail(
			  email: "${email}"
			)
		  }`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		console.log(request);
		
		return request;
	};

	const _deleteCustomer = async (email) => {
		const requestBody = `mutation {
			deleteCustomer (
			  email: "${email}"
			) {
			  result
			}
		  }`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');

		console.log(request);
		
		return request;
	};

	const _orderHistory = async () => {
		const requestBody = `{
			customer {
				orders{
				  total_count
				  items {
							id
							order_date
							number
							shipping_method
							status
							total{
								total_tax{
									value
								}
								base_grand_total{
									value
									currency
								}
							}
							items{
								product_name
								product_sku
								quantity_ordered
								product_type
								product_sale_price{
									currency
									value
								}
								selected_options{
									label
									value
								}
							}
							payment_methods{
								name
								type
								__typename
								additional_data{
									name
									value
									__typename
								}
							}
							
							billing_address{
								firstname
								lastname
								street
								city
								region
								postcode
								country_code
								telephone
							}
							shipping_address{
								firstname
								lastname
								street
								city
								region		
								postcode
								country_code
								telephone
							}
							
				  }
				}
			}
		}`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);
		return request;
	};

	
	const _getOrdersOfPage = async (currentPage) => {
		const requestBody = `{
			customer {
				orders(currentPage: ${currentPage}, pageSize: 5) {
				  items {
							id
							order_date
							number
							shipping_method
							status
							total{
								total_tax{
									value
								}
								base_grand_total{
									value
									currency
								}
							}
							items{
								product_name
								product_sku
								quantity_ordered
								product_type
								product_sale_price{
									currency
									value
								}
								selected_options{
									label
									value
								}
							}
							payment_methods{
								name
								type
								__typename
								additional_data{
									name
									value
									__typename
								}
							}
							
							billing_address{
								firstname
								lastname
								street
								city
								region
								postcode
								country_code
								telephone
							}
							shipping_address{
								firstname
								lastname
								street
								city
								region		
								postcode
								country_code
								telephone
							}
							
				  }
				}
			}
		}`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);
		return request;
	};


	const _getOrderInfo = async (order_number) => {
		const requestBody = `{
			customer {
				orders(
						filter: { 
							number: { eq: "${order_number}" } 
						}){
				  items {
							id
							order_date
							number
							invoices{
								id
								number
								items{
									product_name
								}
							}
							shipments{
								id
								number
								items{
									product_name
									product_sku
									quantity_shipped
								}
							}
							shipping_method
							status
							total{
								total_tax{
									value
								}
								base_grand_total{
									value
									currency
								}
							}
							
							insider_club {
								value
								currency
							}
					
							items{
								product_name
								product_sku
								quantity_ordered
								personalisation {
									front_font
									front_text
									front_orientation
									back_text
									back_font
									back_orientation
									icon
									pattern
								}
								
								product_sale_price{
									currency
									value
								}
								product_price_incl_tax {
									currency
									value
								}
								selected_options{
									label
									value
								}
							}
							payment_methods{
								name
								type
								__typename
								additional_data{
									name
									value
									__typename
								}
							}
							
							billing_address{
								firstname
								lastname
								street
								city
								region
								postcode
								country_code
								telephone
							}
							shipping_address{
								firstname
								lastname
								street
								city
								region		
								postcode
								country_code
								telephone
							}
				  }
				}
			  }
		}`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);
		return request;
	};




	/* Merge Carts */
	const _mergeCarts = async (currentCartId, responseCartId) => {
		const requestBody = `mutation {
			mergeCarts(
			  source_cart_id: "${currentCartId}",
			  destination_cart_id: "${responseCartId}"
			) {
			  items {
				id
				product {
				  name
				  sku
				}
				quantity
			  }
			}
		}`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query).then((response) => {
			if (response) {
				_getCart(responseCartId);
			}

			return response;
		});

		return request;
	};

	/* Check Login */
	const _checkLogin = async () => {
		const requestBody = `{
			me {
				is_logged_in
				active_cart_id
			}
	  	}`;

		const query = JSON.stringify({ query: requestBody });

		const isLoggedIn = await _makePostRequest(query).then((response) => {
			const responseCartId = response.data.me.active_cart_id;
			const isLoggedIn = response.data.me.is_logged_in;

			Utils.setCookie('commerce-auth-verify', isLoggedIn);
			Utils.setCookie('mage-cache-sessid', isLoggedIn);

			if (responseCartId) {
				_setCommerceCookie('commerce-cart-id', responseCartId, 20);
				_getCart(responseCartId);
				window.dispatchEvent(loginAction);
				return true;
			} else {
				_eraseCommerceCookie('commerce-cart-id');
				localStorage.removeItem('commerce-cart-storage');
				sessionStorage.removeItem('customerData');
				window.dispatchEvent(loginAction);
				return false;
			}
		});

		return isLoggedIn;
	};

	/* Login Customer */
	const _loginCustomer = async (sourceCartId, destCartId) => {

		if (Utils.getCookie('commerce-auth-verify') === 'true') {
			const authToken = Utils.getCookie('commerce-auth-token');

			if (authToken && sourceCartId && (sourceCartId !== destCartId)) {
				const loginCustomerOk = await _mergeCarts(sourceCartId, destCartId).then((response) => {
					if (response) {
						if (response.errors) {
							return false;
						}
						else {
							return true;
						}
					}
					else {
						return false;
					}

				});
				return loginCustomerOk;
			}
			else {
				if (!sourceCartId) {
					return true;
				}
				return false;
			}
		}
		else {
			return false;
		}
	};

	/* Logout Customer */
	const _logoutCustomer = () => {
		const authToken = Utils.getCookie('commerce-auth-token');

		if (authToken) {
			console.log("Rimozione cookie associati al customer con token: " + authToken);
			_eraseCommerceCookie('commerce-cart-id');
			_eraseCommerceCookie('commerce-auth-token');
			sessionStorage.removeItem('customerData');
			localStorage.removeItem('loyaltyInfo');
			Utils.removeLocalStorage('commerce-cart-storage');
			Utils.removeLocalStorage('mage-cache-timeout');
			Utils.removeLocalStorage('mage-cache-storage');
			window.dispatchEvent(cartUpdate);
			return true;
		}

		return true;

	};

	const _revokeCustomerToken = async () => {
		const requestBody = `
			mutation {
				revokeCustomerTokenSession {
					result
				}
			}		
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	/* Remove Item From Cart */
	const _removeItemFromCart = async (cartItemId) => {
		const cartId = Utils.getCookie('commerce-cart-id');

		// Trigger cart update window event
		window.dispatchEvent(cartAction);

		// If no cartId or no cartItemId.
		if (!cartId || !cartItemId) {
			return Promise.resolve('Need cartId and cartItemId.');
		}

		const requestBody = `mutation removeItemFromCart($cartId: String!, $cartItemId: Int!) {
			removeItemFromCart(input: { cart_id: $cartId, cart_item_id: $cartItemId }) {
				cart {
					items {
						id
						product {
							name
						}
						quantity
					}
					prices {
						grand_total {
							value
							currency
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId,
			cartItemId,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		/* eslint-disable-next-line no-console */
		console.log('removeItemFromCart', request);

		// Add cart message if in response
		cartMessage = request.message ? request.message : '';

		// Update local storage
		lastActionTaken = 'remove';
		_getCart(cartId);

		return request;
	};

	/* Update Item From Cart */
	const _updateItemInCart = async (cartItemId, quantity) => {
		const cartId = Utils.getCookie('commerce-cart-id');

		// Trigger cart update window event
		window.dispatchEvent(cartAction);

		// If no cartId, cartItemId and cartItemQuantity.
		if (!cartId || !cartItemId || !quantity) {
			return Promise.resolve('Need cartId, cartItemId and quantity.');
		}

		const requestBody = `mutation updateCartItem(
			$cartId: String!
			$cartItemId: Int!
			$quantity: Float!
		) {
			updateCartItems(
				input: {
					cart_id: $cartId
					cart_items: [{ cart_item_id: $cartItemId, quantity: $quantity }]
				}
			) {
				cart {
					items {
						id
						product {
							name
						}
						quantity
					}
					prices {
						grand_total {
							value
							currency
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId,
			cartItemId,
			quantity,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		// Add cart message if in response
		cartMessage = request.message ? request.message : '';

		// Update local storage
		lastActionTaken = 'update';
		_getCart(cartId);

		return request;
	};

	const _clearCartAndAddItem = async (productObject) => {

		const localCart = JSON.parse(
			Utils.getLocalStorage('commerce-cart-storage')
		);

		const cartObj = localCart.data.cart;
		if (cartObj.items && cartObj.items.length > 0) {
			for (const item of cartObj.items) {
				await _removeItemFromCart(item.id);
			}
		}

		return _addItemToCart(productObject);
	}

	/* Add Item To Cart Analytics */
	const _dispatchAddItemToCartEvent = (productObject) => {
		const eventData = {
			event: 'addToCart',
			ecommerce: {
				currencyCode: '',
				add: {
					products: [
						{
							name: productObject.productName,
							id: productObject.productSku,
							price: '',
							brand: 'Glo',
							category: '',
							// variant: 'Grey',
							quantity: productObject.productQuantity,
							format: 'Accessory',
							// flavour: 'apple',
							// nicotine: '6ml',
							systemType: '',
						},
					],
				},
			},
		};

		if (typeof dataLayer !== 'undefined') {
			dataLayer.push(eventData);
		}

		// const addItemToCartEvent = new CustomEvent('addItemToCart', eventData);

		// document.dispatchEvent(addItemToCartEvent);
	};

	/* Add Item To Cart */
	const _addItemToCart = async (productObject) => {
		let cartId = Utils.getCookie('commerce-cart-id');
		let request = '';
		const cartObject = productObject;
		const isWalletProduct = productObject.is_wallet_product == '1';

		// Trigger cart update window event
		window.dispatchEvent(cartAction);

		// Set cart item Name for message bar
		lastActionTaken = 'add';
		lastItemAdded = cartObject.productName;
		// Utils.setCookie('commerce-item-message', cartObject.productName);

		// If no cartId, create one.
		if (!cartId) {
			cartId = await _createEmptyCart();
		}

		const isLoggedIn = Utils.getCookie('commerce-auth-verify') === 'true';

		cartObject.cartId = cartId;

		/* eslint-disable-next-line no-console */
		console.log('addItemToCart cartId', cartObject);

		switch (cartObject.productType) {
			case 'SimpleProduct':
				if (isLoggedIn) {
					const walletCheck = await _walletCartCheck();
					if (walletCheck && walletCheck.data && walletCheck.data.WalletCartCheck) {
						if ((isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_normal)
							|| (!isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_wallet_only)) {
							Utils.setLocalStorage('commerce-product-pending', productObject);
							window.dispatchEvent(mixedCart);
							break;
						}
					}
				}
				if (isWalletProduct) {
					request = await _addVirtualProduct(cartObject);
				} else {
					request = await _addSimpleProduct(cartObject);
				}
				break;
			case 'ConfigurableProduct':
				if (isLoggedIn) {
					const walletCheck = await _walletCartCheck();
					if (walletCheck && walletCheck.data && walletCheck.data.WalletCartCheck) {
						if ((isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_normal)
							|| (!isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_wallet_only)) {
							Utils.setLocalStorage('commerce-product-pending', productObject);
							window.dispatchEvent(mixedCart);
							break;
						}
					}
				}
				request = await _addConfigurableProduct(cartObject);
				break;
			case 'BundleProduct':
				if (isLoggedIn) {
					const walletCheck = await _walletCartCheck();
					if (walletCheck && walletCheck.data && walletCheck.data.WalletCartCheck) {
						if ((isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_normal)
							|| (!isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_wallet_only)) {
							Utils.setLocalStorage('commerce-product-pending', productObject);
							window.dispatchEvent(mixedCart);
							break;
						}
					}
				}
				request = await _addBundleProduct(cartObject);
				break;
			case 'VirtualProduct':
				if (isLoggedIn) {
					const walletCheck = await _walletCartCheck();
					if (walletCheck && walletCheck.data && walletCheck.data.WalletCartCheck) {
						if ((isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_normal)
							|| (!isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_wallet_only)) {
							Utils.setLocalStorage('commerce-product-pending', productObject);
							window.dispatchEvent(mixedCart);
							break;
						}
					}
				}
				request = await _addVirtualProduct(cartObject);
				break;
			default:
		}

		if (request) {
			// Add cart message or error message if in response
			/* eslint-disable-next-line no-nested-ternary */
			cartMessage = request.message
				? request.message
				: request.errors
					? request.errors[0].message
					: '';

			if (request.data && request.data.addProductsToCart && request.data.addProductsToCart.user_errors && request.data.addProductsToCart.user_errors.length) {
				cartMessage = request.data.addProductsToCart.user_errors[0].message;
			} 

			const cart = await _getCart(cartId);

			_dispatchAddItemToCartEvent(productObject);

			return cart;
		}
		return null;
	};

	/* Get Cart Total Quantity */
	const _getCartQuantity = () => {
		const localCart = $.parseJSON(
			Utils.getLocalStorage('commerce-cart-storage')
		);

		let cartQuantity = 0;

		if (
			localCart &&
			localCart.data &&
			localCart.data.cart &&
			localCart.data.cart.items.length > 0
		) {
			cartQuantity = localCart.data.cart.items
				.map((item) => {
					return item.quantity;
				})
				.reduce((prev, next) => {
					return prev + next;
				});
		}

		return cartQuantity;
	};

	const _getCustomerDetails = async () => {

		const customerData = _getSessionStorage('customerData');
		let request = '';

		if(!customerData || customerData === 'undefined') {

			const requestBody = `{
				customer {
					customer_id
					global_loyalty_optin
					codice_fiscale
					firstname
					lastname
					prefix
					email
					gender
					date_of_birth
					is_subscribed
					is_age_verified
					is_approved
					consent_email_marketing
					consent_mobile
					consent_profiling
					salesforce_customer_id
					created_at				  
					addresses {
						id
						firstname
						lastname
						street
						city
						region {
							region_code
							region
						}
						postcode
						country_code
						default_billing
						default_shipping
						telephone
					}
					linked_accounts {
						created_at
						entity_id
						provider_type
						social_id
					}
				}
			}`;

			const query = JSON.stringify({ query: requestBody });

			request = await _makePostRequest(query);
			_setSessionStorage('customerData', request);
			
		} else {
			request = JSON.parse(customerData);
		}

		if (window.location.href.indexOf('/myaccount') > -1 && 
				request &&
				request.data &&
				request.data.customer &&
				request.data.customer.global_loyalty_optin === '0') {
			const syncDevices = await _syncOfflineDevices();
			if (syncDevices && 
					syncDevices.data && 
					syncDevices.data.syncOfflineDevices && 
					syncDevices.data.syncOfflineDevices.status !== 0) {
				sessionStorage.removeItem('customerData');
				request.data.customer.global_loyalty_optin = '' + syncDevices.data.syncOfflineDevices.status;
				_setSessionStorage('customerData', request);
			}
		}

		return request;
	};

	// Get Product Reviews
	const _getProductReviews = async (productSku) => {
		// Catch no productId.
		if (!productSku) {
			return Promise.resolve('Need Product Sku');
		}

		const requestBody = `{
            products(filter: { sku: {eq: "${productSku}"} }) {
				items {
				  name
				  sku
				  rating_summary
					review_count
					reviews(pageSize: 100) {
							items {
								average_rating
								nickname
								summary
								text
								ratings_breakdown {
									name
									value
								}
								created_at
							}
						}
				}
				total_count
				page_info {
				  page_size
				}
			  }
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	};

	const _getReviewsMetadata = async () => {
		const requestBody = `{
            productReviewRatingsMetadata {
				items {
					id
					name
					values {
						value
						value_id
					}
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	}

	const _addReview = async (reviewObject) => {
		// Catch no productId.
		if (!reviewObject) {
			return Promise.resolve('Need Review Object.');
		}

		const requestBody = `mutation createProductReview(
			$sku: String!
			$nickname: String!
			$summary: String!
			$text: String!
			$ratingId: String!
			$ratingValueId: String!
		) {
			createProductReview(input: {
				sku: $sku
				nickname: $nickname
				summary: $summary
				text: $text
				ratings: [{
					id: $ratingId
					value_id: $ratingValueId
				}]
			}) {
				review {
					product {
						sku
					}
					summary
					text
					nickname
					created_at
					average_rating
					ratings_breakdown {
						name
						value
					}
				}
			}
		}`;

		const variablesObj = {
			sku: reviewObject.sku,
			nickname: reviewObject.nickname,
			summary: reviewObject.summary,
			text: reviewObject.text,
			ratingId: reviewObject.ratingId,
			ratingValueId: reviewObject.ratingValueId
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');

		return request;
	};

	const _getPsnOptions = async () => {
		const requestBody = `{
			psnOptions {
				fonts {
					font
					font_name
					font_size
					preview_text
				}
				patterns {
					pattern_name
					pattern_image
					thumbnail_image
				}
				icons {
					icon_name
					icon_image
					thumbnail_image
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	}

	const _getPsnMetadata = async () => {
		const requestBody = `{
			moduleConfig {
			 	personalisation {
					enabled
					configuration {
				  		max_characters_horizontal
				  		max_characters_vertical
				  		regex
				  		price
				  		special_price
				  		disclaimer
				  		restricted_words {
							word
				  		}
					}
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	}

	const _guestSubscribeNewsletter = async (form) => {

		const requestBody = `mutation {
			guestSubscribeNewsletterMarketing (
				email: "${form.email}"
				firstname: "${form.firstname}"
				lastname: "${form.lastname}"
				phone: "${form.phone}"
				marketing_by_email: ${form.marketing_by_email}
				marketing_by_sms: ${form.marketing_by_sms}
				date_of_birth: "${form.date_of_birth}"
				${form.consent_profiling !== null ? `consent_profiling: ${form.consent_profiling}` : '' }
				store_my_data: ${form.store_my_data}
			)
			{
			  	status
			}
		}`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		return request;
	}

	const _walletCartCheck = async () => {
		const requestBody = `{
			WalletCartCheck {
				is_cart_normal
				is_mixed_cart
				is_cart_wallet_only
			}
		}`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		return request;
	}

	/* Get Product Alerts */
	const _getProductAlerts = async () => {
		const requestBody = `{
			productAlert {
				product_id
				status
			}
		}`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		return request;
	};

	/* Add Product Alert */
	const _addProductAlert = async (productId) => {
		// Catch no productId.
		if (!productId) {
			return Promise.resolve('Need productId.');
		}

		const requestBody = `mutation productAlertCustomerNotifyInStock(
			$productId: Int!
		) {
			productAlertCustomerNotifyInStock(
				input: {
					productId: $productId
				}
			) {
				product_id
				status
			}
		}`;

		const variablesObj = {
			productId,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		return request;
	};

	const _getLoyaltyRestrictedSkus = async () => {
		const requestBody = `{
			moduleConfig {
				loyalty_annex_cloud {
					enabled
					configuration {
						dashboard_iframe_url
						restricted_skus {
							sku
						}
					}
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		let restrictedArray = request.data.moduleConfig.loyalty_annex_cloud.configuration.restricted_skus;
		if (restrictedArray && restrictedArray.length > 0) {
			restrictedArray = restrictedArray.map((el) => el.sku);
		}
		return restrictedArray;
	}

	const _getLivechatConfig = async () => {

		const livechatConfig = localStorage.getItem('livechatConfig');

		if(livechatConfig) {
			return JSON.parse(livechatConfig);
		}

		const requestBody = `{
			moduleConfig {
				unyco_chat {
					enabled
					configuration {
						url
						eu_proactive_url
						container_code
						container_id
					}
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		localStorage.setItem('livechatConfig', JSON.stringify(request));
		return request;
	}

	const _deviceRegistration = async () => {
		//TODO registrazione dispositivo
		const requestBody = `query {
			moduleConfig {
			  device_registration {
				enabled
				configuration {
				  has_device_types
				  device_types
				  has_device_sources
				  device_sources
				  has_cms_block_login
				  cms_block_id_login
				  has_cms_block_device_registration
				  cms_block_id_device_registration
				  reward_free_skus_enabled
				  reward_free_skus
				  reward_buy_skus
				}
			  }
			}
		  }`;

		const request = await _makePostRequest(JSON.stringify({query: requestBody}));
		return request;
	}

	const _registerDevice = async (device_id,device_type, device_colour,device_source,coupon_code,postcode) => {
		const requestBody = `mutation {
			registerDevice(
				input: {
					device_id: "${device_id}"
					additional_data: {
						device_type: "${device_type}"
						device_colour: "${device_colour}"
						device_source: "${device_source}"
						coupon_code: "${coupon_code}"
						postcode: "${postcode}"
					}
				}
			) {
				status
				message
			}
		}`;

		const request = await _makePostRequest(JSON.stringify({query: requestBody}));
		sessionStorage.removeItem('customerData');
		if(request && request.data && request.data.registerDevice && request.data.registerDevice.status){
			return true
		}else{
			return false
		}
	}

	const _createCustomerV2 = async (customerObject) => {
		if (!customerObject) {
			return Promise.resolve('Need Customer Object.');
		}

		const requestBody = `
			mutation createCustomerV2(
				$firstname: String!	
				$lastname: String!	
				$dob: String!	
				$gender: Int!	
				$mgm_promo_code: String!
				$codice_fiscale: String!	
				$email: String!	
				$password: String!	
				$street: [String!]	
				$telephone: String!	
				$postcode: String!	
				$city: String!	
				$region: String!	
				$region_code: String!	
				$country_code: CountryCodeEnum
				$consent_email_marketing: String!
				$consent_mobile: String!
				$consent_profiling: String!
				$consent_store_my_data: String!
				$mostly_used_products: String!
				$cigarette_sticks_usage_count: String!
				$reason_on_buyglo: String!
				$reason_on_buyglo_other: String!
			){
				createCustomerV2(
					input: {
						firstname: $firstname
						lastname: $lastname
						email: $email
						password: $password
						dob: $dob
						codice_fiscale: $codice_fiscale
						gender: $gender
						mgm_promo_code: $mgm_promo_code
						address: {
							street: $street
							telephone: $telephone
							postcode: $postcode
							city: $city
							region: {
								region_code: $region_code,
								region: $region
							}
							country_code: $country_code
							firstname: $firstname
							lastname: $lastname
							default_shipping: true
							default_billing: true
						}
						consent_email_marketing: $consent_email_marketing
						consent_mobile: $consent_mobile
						consent_profiling: $consent_profiling
						consent_store_my_data: $consent_store_my_data
						mostly_used_products: $mostly_used_products
						cigarette_sticks_usage_count: $cigarette_sticks_usage_count
						reason_on_buyglo: $reason_on_buyglo
						reason_on_buyglo_other: $reason_on_buyglo_other
						${customerObject.linked_account && customerObject.linked_account.social_id && customerObject.linked_account.provider_type ? `
							linked_account: {
								social_id: "${customerObject.linked_account.social_id}"
        						provider_type: ${customerObject.linked_account.provider_type}
							}
						` : '' }
					}
				) {
					customer {
						firstname
						lastname
						email
						is_approved
						is_age_verified
						created_at
						consent_email_marketing
						consent_mobile
						consent_store_my_data
						consent_profiling
						mostly_used_products
						cigarette_sticks_usage_count
						reason_on_buyglo
						reason_on_buyglo_other
					}
					yoti_token
				}
			}
		
		`;
		const variablesObj = {
			firstname: customerObject.firstname,
			lastname: customerObject.lastname,
			dob: customerObject.dob,
			gender: customerObject.gender,
			mgm_promo_code: customerObject.mgm_promo_code,
			codice_fiscale: customerObject.codice_fiscale,
			email: customerObject.email,
			password: customerObject.password,
			street: customerObject.street,
			telephone: customerObject.telephone,
			postcode: customerObject.postcode,
			city: customerObject.city,
			region_code: customerObject.region_code,
			region: customerObject.region,
			country_code: customerObject.country_code,
			consent_mobile: customerObject.consent_mobile,
			consent_email_marketing: customerObject.consent_email_marketing,
			consent_profiling: customerObject.consent_profiling,
			consent_store_my_data: customerObject.consent_store_my_data,
			mostly_used_products: customerObject.mostly_used_products,
			cigarette_sticks_usage_count: customerObject.cigarette_sticks_usage_count,
			reason_on_buyglo: customerObject.reason_on_buyglo,
			reason_on_buyglo_other: customerObject.reason_on_buyglo_other
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		sessionStorage.removeItem('customerData');

		return request;
	};

	const _createCustomerAddress = async (addressObject) => {
		if (!addressObject) {
			return Promise.resolve('Need Address Object.');
		}
		/*  consent_mobile: $consent_mobile
			consent_email_marketing: $consent_email_marketing
			consent_bat_agreement: $consent_bat_agreement */

		const requestBody = `
			mutation createCustomerAddress(
				$firstname: String!	
				$lastname: String!	
				$street: [String!]	
				$telephone: String!	
				$postcode: String!	
				$city: String!	
				$region: String!
				$country_code: CountryCodeEnum
				$default_shipping: Boolean!
				$default_billing: Boolean!
			){
				createCustomerAddress(
					input: {
						firstname: $firstname
						lastname: $lastname
						street: $street
						telephone: $telephone
						postcode: $postcode
						city: $city
						region: {
							region_code: $region,
							region: $region
						}
						country_code: $country_code
						default_shipping: $default_shipping
						default_billing: $default_billing
					}
				) {
					id
					region {
						region
						region_code
					}
					country_code
					street
					telephone
					postcode
					city
					default_shipping
					default_billing
				}
			}
		
		`;
		const variablesObj = {
			firstname: addressObject.firstname,
			lastname: addressObject.lastname,
			street: addressObject.street,
			telephone: addressObject.telephone,
			postcode: addressObject.postcode,
			city: addressObject.city,
			region: addressObject.region,
			country_code: addressObject.country_code,
			default_shipping: addressObject.default_shipping,
			default_billing: addressObject.default_billing
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');

		return request;
	};

	const _updateCustomerAddress = async (addressObject) => {
		if (!addressObject) {
			return Promise.resolve('Need Address Object.');
		}
		/*  consent_mobile: $consent_mobile
			consent_email_marketing: $consent_email_marketing
			consent_bat_agreement: $consent_bat_agreement */

		const requestBody = `
			mutation updateCustomerAddress(
				$id: Int!
				$firstname: String!	
				$lastname: String!	
				$street: [String!]	
				$telephone: String!	
				$postcode: String!	
				$city: String!	
				$region: String!
				$country_code: CountryCodeEnum
				$default_shipping: Boolean!
				$default_billing: Boolean!
			){
				updateCustomerAddress(
					id: $id,
					input: {
						firstname: $firstname
						lastname: $lastname
						street: $street
						telephone: $telephone
						postcode: $postcode
						city: $city
						region: {
							region_code: $region,
							region: $region
						}
						country_code: $country_code
						default_shipping: $default_shipping
						default_billing: $default_billing
					}
				) {
					id
					region {
						region
						region_code
					}
					country_code
					street
					telephone
					postcode
					city
					default_shipping
					default_billing
				}
			}
		
		`;
		const variablesObj = {
			firstname: addressObject.firstname,
			lastname: addressObject.lastname,
			street: addressObject.street,
			telephone: addressObject.telephone,
			postcode: addressObject.postcode,
			city: addressObject.city,
			region: addressObject.region,
			country_code: addressObject.country_code,
			default_shipping: addressObject.default_shipping,
			default_billing: addressObject.default_billing,
			id: addressObject.id
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');

		return request;
	};
	
	const _deleteCustomerAddress = async (addressId) => {
		if (!addressId) {
			return Promise.resolve('Need address ID.');
		}
		/*  consent_mobile: $consent_mobile
			consent_email_marketing: $consent_email_marketing
			consent_bat_agreement: $consent_bat_agreement */

		const requestBody = `
			mutation deleteCustomerAddress(
				$id: Int!
			){
				deleteCustomerAddress(
					id: $id
				)
			}
		
		`;
		const variablesObj = {
			id: addressId
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');

		return request;
	};
	
	const _createCustomerChatBot = async (customerObject) => {
		if (!customerObject) {
			return Promise.resolve({ errors: [{ message: 'Need Customer Object.' }] });
		}
		console.log("perform submit with customer object: ", customerObject);
		const signupRequest = await _createCustomerV2(customerObject);
		console.log("signuprequest:", signupRequest);
		if (signupRequest && signupRequest.errors) {
			return signupRequest;
		} else {

			const sourceCartId = Utils.getCookie('commerce-cart-id');
			const authTokenRequest = await _setAuthToken(customerObject.email, customerObject.password);

			if (authTokenRequest && authTokenRequest.errors) {
				return authTokenRequest;
			} else {

				const isLoggedIn = await _checkLogin();

				if (isLoggedIn) {

					const destCartId = Utils.getCookie('commerce-cart-id');

					const loginCustomerOk = await _loginCustomer(sourceCartId, destCartId);

					return loginCustomerOk;
				} else {
					return isLoggedIn;
				}

			}
		}
	}

	const _changeCustomerPreferences = async (customerObject) => {
		if (!customerObject) {
			return Promise.resolve('No customer object provided!');
		}

		const requestBody = `mutation { 
			updateCustomerV2 (
			  	input: {
					consent_mobile: "${customerObject.consent_mobile}"
					consent_email_marketing: "${customerObject.consent_email_marketing}"
					consent_profiling: "${customerObject.consent_profiling}"
			  	}
			) {
			  	customer {
					firstname
					lastname
					consent_mobile
					consent_email_marketing
					consent_profiling
			  	}
			}
		}`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');

		console.log("Request",request)
		
		return request;
	};

	const _loyaltyPointsAssignment = async (customerObject) => {
		if (!customerObject) {
			return Promise.resolve('No customer object provided!');
		}

		const requestBody = `
			mutation {
				loyaltyPointAssignment(input: { 
					coupon_code: "${customerObject.coupon_code}"
					customer_id: ${customerObject.customer_id}
				}) {
					status
					message
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');

		return request;
	};

	const _loyaltyServiceUpdate = async (choice) => {
		if (!choice) {
			return Promise.resolve('No choice provided!');
		}

		const requestBody = `
			mutation {
				loyaltyServiceUpdate(
					input : {
					  	user_optin_status: "${choice}"
					}
				) {
					result
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');

		return request;
	};

	const _createFanizeLoyaltyUser = async (choice) => {
		if (!choice) {
			return Promise.resolve('No choice provided!');
		}

		const requestBody = `
			mutation {
				createFanizeLoyaltyUser(input: {
					user_optin_status: "${choice}"
				}) {
					status
					message
					validationErrors
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);
		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');

		return request;
	};

	const _customerPaymentTokens = async () => {

		const requestBody = `
			query {
				customerPaymentTokens {
					items {
						details
						public_hash
						payment_method_code
						type
					}
				}
			}
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		let paymentTokens = [];

		if (request && request.data && request.data.customerPaymentTokens) {
			const items = request.data.customerPaymentTokens.items;


			items.forEach(item => {
				let details = JSON.parse(item.details);
				
				let paymentToken = {};
				
				paymentToken.cardEnding = details.maskedCC;
				paymentToken.expireDate = details.expirationDate;
				paymentToken.detailType = details.type;
				paymentToken.schemeTransactionId = details.schemeTransactionId;
				paymentToken.paymentMethodCode = item.payment_method_code;
				paymentToken.publicHash = item.public_hash;
				paymentToken.type = item.type;

				paymentTokens.push(paymentToken);
			});
		}

		return paymentTokens;
	}

	const _removeCustomerPaymentTokens = async (public_hash) => {

		const requestBody = `
			mutation deletePaymentToken($public_hash: String!) {
				deletePaymentToken(public_hash:$public_hash) {
					result
					customerPaymentTokens {
						items {
							details
							public_hash
							payment_method_code
							type
						}
					}
				}
			}
		`;

		const variablesObj = {
			public_hash
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		let paymentTokens = [];
		let result;

		if (request && request.data && request.data.result && request.data.customerPaymentTokens) {
			const items = request.data.customerPaymentTokens.items;

			result = request.data.result;

			items.forEach(item => {
				let details = JSON.parse(item.details);

				let paymentToken = {};

				paymentToken.cardEnding = details.maskedCC;
				paymentToken.expireDate = details.expirationDate;
				paymentToken.detailType = details.type;
				paymentToken.schemeTransactionId = details.schemeTransactionId;
				paymentToken.paymentMethodCode = item.payment_method_code;
				paymentToken.publicHash = item.public_hash;
				paymentToken.type = item.type;

				paymentTokens.push(paymentToken);
			});
		}

		let response = {
			result: result,
			customerPaymentTokens: paymentTokens
		};

		return response;
	}

	const _customerMgmService = async () => {

		const requestBody = `
			mutation {
				customerMgmService {
					mgm_code
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	};

	const _blogPostInfo = async (postId) => {

		if (!postId) {
			return Promise.resolve('No post ID provided!');
		}

		const requestBody = `
			query {
				blogPostInfo(id: ${postId}) {
				  	vote_coin_label
				  	vote_coin_code
				  	vote_coin_points
				  	vote_action_status
				  	video_action_status
				}
			}
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _blogPostActionLike = async (postId, action, postTitle) => {

		if (!postId || !action) {
			return Promise.resolve('No post ID or action provided!');
		}

		const requestBody = `
			mutation{
				blogPostSubmit(
				input:{
					post_id: ${postId}
					post_title: "${postTitle}"
					action: "${action}"
				}){
					status
					prevActionAvailable
					errorMessage
				}
			}
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');

		return request;
	}

	const _blogPostActionVideo = async (postId, value, postTitle) => {

		if (!postId || !value) {
			return Promise.resolve('No post ID or value provided!');
		}

		const requestBody = `
			mutation{
				blogPostSubmit(
				input:{
					post_id: ${postId}
					post_title: "${postTitle}"
					action: "watch"
					video_coin_type:"${value}"
				}) {
					status
					prevActionAvailable
					errorMessage
				}
			}
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');

		return request;
	}

	const _loyaltyUserInfo  = async () => {

		const customerData = localStorage.getItem('loyaltyInfo');

		if (customerData && customerData !== 'undefined') {
			const { datas, expires } = JSON.parse(customerData);
			if (!datas || !expires || expires < Date.now()) {
				localStorage.removeItem('loyaltyInfo');
			} else {
				return datas;
			}
		}

		const requestBody = `
			query {
				loyaltyUserInfo {
					user_tier
					tier_completed_percentage
					points
					coins
				}
			}			  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		const saveLoyalty = {
			expires: Math.round(600 * 1000 + Date.now()),
			datas: request
		};

		localStorage.setItem('loyaltyInfo', JSON.stringify(saveLoyalty));

		return request;
	}

	const _syncOfflineDevices = async () => {

		const requestBody = `
			mutation {
				syncOfflineDevices
				{
					status
				}
			}
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _loyaltyUserTier  = async () => {

		const requestBody = `
			query {
				loyaltyUserInfo {
					user_tier
				}
			}			  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _pointsHistory  = async () => {

		const requestBody = `
			query {
				pointsHistory {
					type
					title
					coins
					points
					created_at
				}
			}			  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _getMissionList  = async () => {

		const requestBody = `
			query {
				getMissionList {
					mission_type
					id
					points
					claim
					available
					type
					title
					image
				}
			}		  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _getQuestions  = async (surveyID) => {

		if (!surveyID) {
			return Promise.resolve('No Survey ID provided!');
		}

		const requestBody = `
			query {
				getQuestions(surveyId: "${surveyID}") {
					id
					type
					order
					survey_id
					image
					text
					options {
						id
						type
						order
						parent_survey_question_id
						survey_id
						image
						text
					}
				}
			}		  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _saveAnswers  = async (surveyID, answers) => {

		if (!surveyID) {
			return Promise.resolve('No Survey ID provided!');
		}

		let stringAnswers = '';

		answers.forEach((el, index) => {
			stringAnswers += `{
				id: "${el.id}",
				answer: "${el.answer}"
			}${index < (answers.length - 1) ? ',' : ''}`;
		});

		const requestBody = `
			query {
				saveAnswers(
					surveyId: "${surveyID}"
					answers: [${stringAnswers}]
				) {
				status
				message
				}
			}				  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');

		return request;
	}

	const _getStores = async (filter) => {

		if(window.localforage && (!filter || (!filter.store && !filter.activity))) {
			const storeDatas = await localforage.getItem('allStores');
			if(storeDatas) {
				const { allStores, expires } = storeDatas;
				if(!expires || expires < Date.now()) {
					localforage.removeItem('allStores');
				} else if(allStores && allStores.length > 0) {
					return allStores;
				}
			}
		}

		let stringFilter = "";

		if (filter && filter.store) {
			stringFilter = `store_type: { eq: "${filter.store}" }`;
		}

		else if (filter && filter.activity) {
			stringFilter = `activity_type: {eq: "${filter.activity}"}`;
		}

		const requestBody = 
		`
			query {
				storeLocatorFilter(
				filters: { 
					${stringFilter}
				} ) {
					physicalStores {
						online
						title
						company_name
						address
						city
						state
						zipcode
						country_code
						phone
						latitude
						longitude
						store_type
						activity_type
					}
				}
			}
		`;

		const request = await _makeGetRequest(requestBody);

		if (request && request.data && request.data.storeLocatorFilter) {
			const physicalStores = request.data.storeLocatorFilter.physicalStores;
			if(window.localforage && (!filter || (!filter.store && !filter.activity))) {
				const dataToStore = {
					allStores: physicalStores,
					expires: Math.round(86400 * 1000 + Date.now())
				}
				await localforage.setItem('allStores', dataToStore);
			}
			return physicalStores;
		} 
		else {
			return null;
		}

	}

	const _getBlogPosts = async () => {

		const requestBody = 
		`
			{
				blogPosts(currentPage: 1, pageSize: 500, sort: ["DESC"]) {
					total_count
					items {
						post_id
						identifier
						title
						canonical_url
						post_url
						creation_time
					}
					total_pages
				}
			}
		`;
		const request = await _makeGetRequest(requestBody);

		if (request && request.data && request.data.blogPosts) {
			const blogPosts = request.data.blogPosts.items;
			return blogPosts;
		} 
		else {
			return null;
		}

	}

	const _validateGloNumber = async (gloNumber) => {
		const requestBody = `query {
			validateDevice (
				input: {
					device_id: "${gloNumber}"
				}
		) {
				status
				message
			}
		}`;

		console.log("requestBody", requestBody);

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
	
		if (request && request.data && request.data.validateDevice && request.data.validateDevice.status) {
			return request.data.validateDevice.status;
		}
		else {
			return false;
		}
	}

	const _getSocialLoginInfo = async () => {

		const requestBody = `
			query {
				moduleConfig {
					social_login {
						enabled
						configuration {
							facebook_enabled
							facebook_sort_order
							facebook_app_id
							google_enabled
							google_sort_order
							google_app_id
						}
					}
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

	const _socialLogin = async (socialLoginObject) => {

		const requestBody = `

			mutation gloItalySocialLogin(
				$type: GloItalySocialTypeEnum!,
				$email: String!,
				$social_id: String!
			){
				gloItalySocialLogin(
					input:{
						type: $type,
						email: $email,
						social_id: $social_id
					}
				){
					token
				}
			}
		`;

		const variablesObj = {
			"type": socialLoginObject.type,
			"email": socialLoginObject.email,
			"social_id": socialLoginObject.social_id
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query).then((response) => {
			const { errors } = response;

			if (!errors) {
				const authToken = response.data.gloItalySocialLogin.token;
				_setCommerceCookie('commerce-auth-token', authToken, 60);
				sessionStorage.removeItem('customerData');
				localStorage.removeItem('loyaltyInfo');
				return { authToken };
			}
			return { errors };
		});

		return request;
	};

	const _optOutFanizeLoyaltyUser = async () => {

		const requestBody = `
			mutation {
				optOutFanizeLoyaltyUser(input: {
			  		user_optout_status: "Yes"
				}) {
			  		status
			  		message
				}
		  	}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);
		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');

		return request;
	}

	

	const _getproductReviewStatus = async (sku) =>{
		const requestBody = `query {
			productReviewStatus(sku: "${sku}") {
				status
				label
			  }
		}`;


		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
	
		return request;
	}

	const _salesforceRegistration = async (customerObject) => {
		
		const requestBody = `
		  mutation simplifiedRegistration (
			  $codice_fiscale: String!	
			  $password: String!	
			  $street: [String!]	
			  $postcode: String!	
			  $city: String!	
			  $region: String!	
			  $region_code: String!	
			  $country_code: CountryCodeEnum
			  $salesforce_id: String!
			  $loyalty_optin: Int!
		  ){
				simplifiedRegistration (
				  input: {
					  password: $password
					  codice_fiscale: $codice_fiscale
					  salesforce_id: $salesforce_id,
					  loyalty_optin: $loyalty_optin
					  address: {
						  street: $street
						  postcode: $postcode
						  city: $city
						  region: {
							  region_code: $region_code,
							  region: $region
						  }
						  country_code: $country_code
						  default_shipping: true
						  default_billing: true
					  }
				  }
			  ) {
				  customer {
					  firstname
					  lastname
					  email
				  }
			  }
		  }
	  
	  `;
		const variablesObj = {
				codice_fiscale: customerObject.codice_fiscale,
				password: customerObject.password,
				street: customerObject.street,
				postcode: customerObject.postcode,
				city: customerObject.city,
				region: customerObject.region,
				region_code: customerObject.region_code,
				country_code: customerObject.country_code,
				salesforce_id: customerObject.salesforce_id,
				loyalty_optin: customerObject.loyalty_optin
		};

		const query = JSON.stringify({
				query: requestBody,
				variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		console.log("Request",request)
		
		return request;
	};



	_constructor();

	// output/public
	return {
		createEmptyCart: _createEmptyCart,
		addItemToCart: _addItemToCart,
		updateItemInCart: _updateItemInCart,
		getCart: _getCart,
		getCartQuantity: _getCartQuantity,
		removeItemFromCart: _removeItemFromCart,
		getProduct: _getProduct,
		priceFormatter: _priceFormatter,
		getProductCatalog: _getProductCatalog,
		getCategoryList: _getCategoryList,
		getProductCategoryList: _getProductCategoryList,
		getProductAggregations: _getProductAggregations,
		getStrengthTable: _getStrengthTable,
		setAuthToken: _setAuthToken,
		loginCustomer: _loginCustomer,
		logoutCustomer: _logoutCustomer,
		setSessionStorage: _setSessionStorage,
		getSessionStorage: _getSessionStorage,
		getProductReviews: _getProductReviews,
		getReviewsMetadata: _getReviewsMetadata,
		addReview: _addReview,
		guestSubscribeNewsletter: _guestSubscribeNewsletter,
		getPsnOptions: _getPsnOptions,
		getPsnMetadata: _getPsnMetadata,
		getCustomerDetails: _getCustomerDetails,
		walletCartCheck: _walletCartCheck,
		clearCartAndAddItem: _clearCartAndAddItem,
		getProductAlerts: _getProductAlerts,
		addProductAlert: _addProductAlert,
		getLoyaltyRestrictedSkus: _getLoyaltyRestrictedSkus,
		getLivechatConfig: _getLivechatConfig,
		createCustomerV2: _createCustomerV2,
		createCustomerChatBot: _createCustomerChatBot,
		checkLogin: _checkLogin,
		customerPaymentTokens: _customerPaymentTokens,
		removeCustomerPaymentTokens: _removeCustomerPaymentTokens,
		createCustomerAddress: _createCustomerAddress,
		deleteCustomerAddress: _deleteCustomerAddress,
		updateCustomerAddress: _updateCustomerAddress,
		revokeCustomerToken: _revokeCustomerToken,
		changeCustomerPassword: _changeCustomerPassword,
		requestPasswordResetEmail: _requestPasswordResetEmail,
		resetPassword: _resetPassword,
		deleteCustomer: _deleteCustomer,
		orderHistory: _orderHistory,
		getOrderInfo: _getOrderInfo,
		getOrdersOfPage: _getOrdersOfPage,
		getBlogPosts: _getBlogPosts,
		blogPostInfo: _blogPostInfo,
		blogPostActionLike: _blogPostActionLike,
		blogPostActionVideo: _blogPostActionVideo,
		createCustomerChatBot: _createCustomerChatBot,
		changeCustomerPreferences: _changeCustomerPreferences,
		loyaltyPointsAssignment: _loyaltyPointsAssignment,
		loyaltyServiceUpdate: _loyaltyServiceUpdate,
		loyaltyUserInfo: _loyaltyUserInfo,
		loyaltyUserTier: _loyaltyUserTier,
		syncOfflineDevices: _syncOfflineDevices,
		pointsHistory: _pointsHistory,
		createFanizeLoyaltyUser: _createFanizeLoyaltyUser,
		getMissionList: _getMissionList,
		getQuestions: _getQuestions,
		saveAnswers: _saveAnswers,
		getStores: _getStores,
		customerMgmService: _customerMgmService,
		getSocialLoginInfo: _getSocialLoginInfo,
		socialLogin: _socialLogin,
		validateGloNumber: _validateGloNumber,
		registerDevice: _registerDevice,
		deviceRegistration: _deviceRegistration,
		optOutFanizeLoyaltyUser: _optOutFanizeLoyaltyUser,
		getproductReviewStatus:_getproductReviewStatus,
		salesforceRegistration: _salesforceRegistration
	};
})();
