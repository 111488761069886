const PdfGenerator = (() => {

    const configObj = {
        outputType: "save",
        returnJsPDFDocObject: true,
        fileName: "Invoice 2022",
        orientationLandscape: false,
        compress: true,
        business: {
            name: "ORDER CONFIRMATION",
        },
        contact: {
            label: "Venduto a:",
            name: "Stefano Silvi",
            address: "Via Bubba, 13",
            phone: "Roma, Roma, 00147",
            email: "Italia",
            otherInfo: "T: +39546268",
        },
        shipping: {},
        paymentMethod: {
            label: "Metodo di pagamento",
            name: "Card Payments",
        },
        shippingMethod: {},
        invoice: {
            label: "Ordine n. : ",
            num: 19,
            invDate: "Data Ordine: 01/01/2021 18:12",
            headerBorder: false,
            tableBodyBorder: false,
            header: [
            { 
                title: "Sku",
                style: {
                width: 40
                } 
            }, 
            { 
                title: "Prodotto",
                style: {
                width: 70
                } 
            }, 
            { title: "Prezzo"},
            { title: "Quantità"},
            { title: "Subtotale"}
            ],
            table: Array.from(Array(15), (item, index)=>([
                "There are many variations ",
                "Lorem Ipsum is simply dummy text dummy text ",
                200.5,
                1,
                400.5
            ])),
            additionalRows: [{
                col1: 'Subtotale:',
                col2: '',
                col3: '145,250.50',
                style: {
                    font: 'normal',
                    fontSize: 12
                }
            },
            {
                col1: 'Totale complessivo (Escl. Tasse):',
                col2: '',
                col3: '20',
                style: {
                    font: 'bold',
                    fontSize: 12
                }
            },
            {
                col1: 'Glo IT (22%):',
                col2: '',
                col3: '116,199.90',
                style: {
                    font: 'bold',
                    fontSize: 12
                }
            },
            {
                col1: 'Totale complessivo (Incl. Tasse):',
                col2: '',
                col3: '116,199.90',
                style: {
                    font: 'bold',
                    fontSize: 12
                }
            }],
        },
        footer: {
            text: "",
        },
        pageEnable: true,
        pageLabel: "Page ",
    };

    const _printOrderPdf = (order) => {
        if(!order) return;
        const outputObj = _fillOrderDetails(order);
        PdfInvoiceCustom.jsPDFCustomInvoiceTemplate( outputObj );
    };

    const _printInvoicePdf = (order) => {
        if(!order || order.invoices.length === 0) return;
        const outputObj = _fillOrderDetails(order);
        outputObj.business.name = 'TAX INVOICE';
        const invoice = order.invoices[0];
        outputObj.fileName = 'invoice_' + invoice.number;
        outputObj.invoice.label = 'Fattura #:';
        outputObj.invoice.num = invoice.number;
        outputObj.invoice.orderNum = 'Ordine n. : ' + order.number;
        outputObj.invoice.invGenDate = '' + outputObj.invoice.invDate;
        outputObj.invoice.invDate = 'Data fattura: ' + new Date(order.order_date).toLocaleDateString('it-IT');
        PdfInvoiceCustom.jsPDFCustomInvoiceTemplate( outputObj );
    }

    const _printShipmentPdf = (order) => {
        if(!order || order.shipments.length === 0) return;
        const outputObj = _fillOrderDetails(order);
        outputObj.business.name = 'PACKING SLIP';
        const shipment = order.shipments[0];
        outputObj.fileName = 'shipment_' + shipment.number;
        outputObj.invoice.label = 'Spedizione #:';
        outputObj.invoice.num = shipment.number;
        outputObj.invoice.orderNum = 'Ordine n. : ' + order.number;
        outputObj.invoice.invGenDate = '' + outputObj.invoice.invDate;
        outputObj.invoice.invDate = 'Data spedizione: ' + new Date(order.order_date).toLocaleDateString('it-IT');
        
        outputObj.invoice.header = [
            { 
                title: "Sku",
                style: {
                width: 40
                } 
            }, 
            { 
                title: "Prodotto",
                style: {
                width: 70
                } 
            }, 
            { title: "Quantità"}
        ];

        const orderItems = [];
        order.items.map((orderItem) => {
            const accum = [orderItem.product_sku, 
                orderItem.product_name,
                orderItem.quantity_ordered];
            orderItems.push(accum);
        })

        outputObj.invoice.table = orderItems;

        outputObj.invoice.additionalRows = [];
        
        PdfInvoiceCustom.jsPDFCustomInvoiceTemplate( outputObj );
    }

    const _fillOrderDetails = (order) => {
        const outputObj = {...configObj};
        outputObj.fileName = 'order_' + order.number;
        outputObj.invoice.num = order.number;
        outputObj.invoice.invDate = 'Data ordine: ' + new Date(order.order_date).toLocaleDateString('it-IT');
        const billing_address = order.billing_address;
        const shipping_address = order.shipping_address;
        outputObj.contact.name = billing_address.firstname + ' ' + billing_address.lastname;
        outputObj.contact.address = billing_address.street[0];
        outputObj.contact.phone = billing_address.city + ', ' + billing_address.region + ', ' + billing_address.postcode;
        outputObj.contact.otherInfo = 'T: ' + billing_address.telephone;
        if(shipping_address) {
            outputObj.shipping.label = 'Spedire a:'
            outputObj.shipping.name = shipping_address.firstname + ' ' + billing_address.lastname;
            outputObj.shipping.address = shipping_address.street[0];
            outputObj.shipping.phone = shipping_address.city + ', ' + shipping_address.region + ', ' + shipping_address.postcode;
            outputObj.shipping.email = 'Italia';
            outputObj.shipping.otherInfo = 'T: ' + shipping_address.telephone;
        }
        outputObj.paymentMethod.name = order.payment_methods[0].name;
        if(order.shipping_method) {
            outputObj.shippingMethod.label = 'Metodo di spedizione';
            outputObj.shippingMethod.name = order.shipping_method;
        }

        const orderItems = [];
        order.items.map((orderItem) => {
            const itemPrice = parseFloat(orderItem.product_price_incl_tax.value).toFixed(2);
            const accum = [orderItem.product_sku, 
                orderItem.product_name, 
                itemPrice + ' €', 
                orderItem.quantity_ordered, 
                (itemPrice * orderItem.quantity_ordered).toFixed(2) + ' €'];
            orderItems.push(accum);
        })

        outputObj.invoice.table = orderItems;
        outputObj.invoice.additionalRows[0].col3 = parseFloat(order.total.base_grand_total.value).toFixed(2) + ' €';
        outputObj.invoice.additionalRows[1].col3 = parseFloat(order.total.base_grand_total.value - order.total.total_tax.value).toFixed(2) + ' €';
        outputObj.invoice.additionalRows[2].col3 = parseFloat(order.total.total_tax.value).toFixed(2) + ' €';
        outputObj.invoice.additionalRows[3].col3 = parseFloat(order.total.base_grand_total.value).toFixed(2) + ' €';

        return outputObj;
    };

    return {
        printOrderPdf: _printOrderPdf,
        printInvoicePdf: _printInvoicePdf,
        printShipmentPdf: _printShipmentPdf
    };

})();