// Dependent Upon
// -
// Modules (static)
// - Utils

// ------------------ Util (static)
/* eslint-disable-next-line no-unused-vars */
const Utils = (() => {
	// private var(s)

	// private method(s)
	const _constructor = () => {
		// console.log('Util._constructor()');
	};

	const _loadJSON = (jsonLocation, onComplete) => {
		// Util.log('Util._loadJSON() json_location : ' + json_location);
		$.ajax({
			url: jsonLocation,
			dataType: 'text',
			success(string) {
				const data = $.parseJSON(string);
				// Util.log('Util._loadJSON() data : ', data);
				if (onComplete && typeof onComplete === 'function')
					onComplete(data);
			},
		});
	};

	const _loadHTML = (htmlLocation, onComplete) => {
		// Util.log('Util._loadHTML() html_location : ' + html_location);
		$.ajax({
			url: htmlLocation,
			// context: document.body,
			dataType: 'html',
			success(data) {
				// Util.log('Util._loadHTML() data : ', data);
				if (onComplete && typeof onComplete === 'function')
					onComplete(data);
			},
		});
	};

	const _loadText = (location, onComplete) => {
		// Util.log('Util._loadText() location : ' + location);
		$.ajax({
			url: location,
			dataType: 'text',
			success(data) {
				// Util.log('Util._loadText() data : ', data);
				if (onComplete && typeof onComplete === 'function')
					onComplete(data);
			},
		});
	};

	const _split = (string, delimiter) => {
		if (typeof string !== 'string') return string;
		// string = string.replace(/\s/g, ''); // remove white space
		const tempString = string.replace(/^\s+|\s+$/g, ''); // remove white space up to first charactor and after last charactor
		// when we drop support for ie 8
		// string = string.trim(); // remove white space up to first charactor and after last charactor
		return tempString.split(delimiter); // create array
	};

	const _uid = () => {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
			const r = Math.random() * 16 || 0;
			const v = c === 'x' ? r : (r && 0x3) || 0x8;
			return v.toString(16);
		});
	};

	// array specific
	const _removeBlankStrings = (array) => {
		const newArray = [];
		for (let i = 0; i < array.length; i++) {
			const iteredItem = array[i];
			if (iteredItem !== '') newArray.push(iteredItem);
		}
		return newArray;
	};

	const _shuffleArray = (o) => {
		for (
			let j, x, i = o.length;
			i;
			j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x
		);
		return o;
	};

	const _removeArrayDuplicates = (array) => {
		const a = array.concat();
		for (let i = 0; i < a.length; ++i) {
			for (let j = i + 1; j < a.length; ++j) {
				if (a[i] === a[j]) a.splice(j--, 1);
			}
		}
		return a;
	};

	const _mergeArrays = (...args) => {
		let i;
		let ii;
		const newArray = [];
		const arrayAmount = args.length;
		let iteredArray;
		let iteredArrayContentsAmount;

		for (i = 0; i < arrayAmount; i++) {
			iteredArray = args[i];
			iteredArrayContentsAmount = iteredArray.length;
			for (ii = 0; ii < iteredArrayContentsAmount; ii++) {
				newArray.push(iteredArray[ii]);
			}
		}
		return newArray;
	};

	// Match on object property values
	const _isSubset = (superObj, subObj) => {
		return Object.keys(subObj).every((ele) => {
			if (typeof subObj[ele] === 'object') {
				return _isSubset(superObj[ele], subObj[ele]);
			}

			// return false if property does not exist.
			if (!superObj[ele]) {
				return false;
			}

			return subObj[ele].toString() === superObj[ele].toString();
		});
	};

	const _randomItemFromArray = (array) => {
		return array[Math.floor(Math.random() * array.length)];
	};

	// from : https://remysharp.com
	const _debounce = (fn, delay) => {
		let timer = null;
		return () => {
			const context = this;
			const args = [fn, delay];
			clearTimeout(timer);
			timer = setTimeout(() => {
				fn.apply(context, args);
			}, delay);
		};
	};

	// from : https://remysharp.com
	const _throttle = (fn, threshhold = 250, scope) => {
		let last;
		let deferTimer;
		return () => {
			const context = scope || this;

			const now = +new Date();
			const args = [fn, threshhold, scope];
			if (last && now < last + threshhold) {
				// hold on to it
				clearTimeout(deferTimer);
				deferTimer = setTimeout(() => {
					last = now;
					fn.apply(context, args);
				}, threshhold);
			} else {
				last = now;
				fn.apply(context, args);
			}
		};
	};

	const _booleanHelper = (string) => {
		if (typeof string === 'boolean') return string;
		if (typeof string === 'undefined') return false;
		switch (string.toLowerCase()) {
			case 'true':
			case 'yes':
			case '1':
				return true;
			case 'false':
			case 'no':
			case '0':
			case null:
				return false;
			default:
				return Boolean(string);
		}
	};

	const _getQueryParameters = (_s) => {
		const locationSearch = window.location.search
			? window.location.search
			: '';
		const query = _s || locationSearch;
		if (!query) return false;
		const parameters = query.replace('?', '').split('&');
		const result = {};
		if (!parameters.length) return false;
		for (let i = 0; i < parameters.length; i++) {
			const iteredParam = parameters[i];
			const item = iteredParam.split('=');
			result[item[0]] = decodeURIComponent(item[1]);
		}
		return result;
	};

	const _getViewportSize = () => {
		const vw = Math.max(
			document.documentElement.clientWidth || 0,
			window.innerWidth || 0
		);
		const vh = Math.max(
			document.documentElement.clientHeight || 0,
			window.innerHeight || 0
		);

		return { viewportWidth: vw, viewportHeight: vh };
	};

	const _getPosition = (element) => {
		let xPosition = 0;
		let yPosition = 0;

		while (element) {
			let $el = element;
			xPosition += $el.offsetLeft - $el.scrollLeft + $el.clientLeft;
			yPosition += $el.offsetTop - $el.scrollTop + $el.clientTop;
			$el = $el.offsetParent;
		}
		return { x: xPosition, y: yPosition };
	};

	const _addEvent = (object, type, callback) => {
		if (object == null || typeof object === 'undefined') return;
		if (object.addEventListener) {
			object.addEventListener(type, callback, false);
		} else if (object.attachEvent) {
			object.attachEvent(`on${type}`, callback);
		} else {
			object[`on${type}`] = callback;
		}
	};

	const _setCookie = (key, value, expiry) => {
		const expires = new Date();
		const isSecure = window.location.protocol === 'https:';
		expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);

		document.cookie = `${key}=${value};${isSecure ? 'secure;' : ''
			}samesite=strict;expires=${expires.toUTCString()};path=/`;
	};

	const _getCookie = (key) => {
		const keyValue = document.cookie.match(`(^|;) ?${key}=([^;]*)(;|$)`);
		return keyValue ? keyValue[2] : null;
	};

	const _eraseCookie = (key) => {
		const keyValue = _getCookie(key);
		_setCookie(key, keyValue, '-1');
	};

	const _setLocalStorage = (id, obj) => {
		// Put the object into storage
		localStorage.setItem(id, JSON.stringify(obj));

		return true;
	};

	const _getLocalStorage = (id) => {
		const storageObject = localStorage.getItem(id);

		// Retrieve the object from storage
		return storageObject;
	};

	const _removeLocalStorage = (id) => {
		// Remove the object from storage
		localStorage.removeItem(id);

		return true;
	};

	const $html = $('html, body');
	const $main = $('main');
	let scrollTop;

	const _lockBody = () => {
		if (window.pageYOffset) {
			scrollTop = window.pageYOffset;

			$main.css({
				top: -scrollTop,
			});
		}

		$html.css({
			height: '100%',
			overflow: 'hidden',
		});
	};

	const _unlockBody = () => {
		$html.css({
			height: '',
			overflow: '',
		});

		$main.css({
			top: '',
		});

		window.scrollTo(0, scrollTop);
		window.setTimeout(() => {
			scrollTop = null;
		}, 0);
	};

	const _getLatitudeAndLongitude = async () => {
		const position = await new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					resolve(position);
				},
				(error) => {
					reject(error);
				}
			);
		}).catch((error) => {
			console.error(error);
		});

		if (position) {
			return {
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			};
		}

		return false;
	}

	const _kilometersBetweenPositions = (lat1, lon1, lat2, lon2) => {
		const R = 6371; // Radius of the earth in km
		const dLat = deg2rad(lat2 - lat1); // deg2rad below
		const dLon = deg2rad(lon2 - lon1);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(deg2rad(lat1)) *
			Math.cos(deg2rad(lat2)) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c; // Distance in km
		return d.toFixed(2);
	}

	const deg2rad = (deg) => {
		return deg * (Math.PI / 180);
	}


	_constructor();

	// output/public
	return {
		loadJSON: _loadJSON,
		loadHTML: _loadHTML,
		loadText: _loadText,
		// string
		String: {
			split: _split,
			uid: _uid,
		},
		// array todo:organize and name accordingly on the private level
		Array: {
			removeBlankStrings: _removeBlankStrings,
			shuffle: _shuffleArray,
			removeDuplicates: _removeArrayDuplicates,
			merge: _mergeArrays,
			randomItemFromArray: _randomItemFromArray,
		},
		//
		debounce: _debounce,
		throttle: _throttle,
		booleanHelper: _booleanHelper,
		getQueryParameters: _getQueryParameters,
		getPosition: _getPosition,
		getViewportSize: _getViewportSize,
		addEvent: _addEvent,
		setCookie: _setCookie,
		getCookie: _getCookie,
		eraseCookie: _eraseCookie,
		setLocalStorage: _setLocalStorage,
		getLocalStorage: _getLocalStorage,
		removeLocalStorage: _removeLocalStorage,
		lockBody: _lockBody,
		unlockBody: _unlockBody,
		isSubset: _isSubset,
		getLatitudeAndLongitude: _getLatitudeAndLongitude,
		kilometersBetweenPositions: _kilometersBetweenPositions,
	};
})();
