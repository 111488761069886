//CodiceFiscale
/* eslint-disable-next-line no-unused-vars */

const CodiceFiscale = (() => {

	var monthLiterals = ['A', 'B', 'C', 'D', 'E', 'H', 'L', 'M', 'P', 'R', 'S', 'T']

	const getConsonants = (str) => {
		return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, '')
	}

	const getVowels = (str) => {
		return str.replace(/[^AEIOU]/gi, '')
	}
	
	const _getNameCode = (name) => {
		var nameCode = getConsonants(name)
		if (nameCode.length >= 4) {
			nameCode =
				nameCode.charAt(0) +
				nameCode.charAt(2) +
				nameCode.charAt(3)
		} else {
			nameCode += getVowels(name)
			nameCode += "XXX"
			nameCode = nameCode.slice(0, 3)
		}

		if (nameCode == "") {
			throw new Error("name")
		}

		return nameCode.toUpperCase()
	}

	const _getSurnameCode = (surname) => {
		var surnameCode = getConsonants(surname)
		surnameCode += getVowels(surname)
		surnameCode += "XXX"
		surnameCode = surnameCode.slice(0, 3)

		if (surnameCode == "") {
			throw new Error("surname")
		}

		return surnameCode.toUpperCase()
	}

	const _getDateAndSexCodes = (date, sex) => {
		if (sex.toUpperCase() != "M" && sex.toUpperCase() != "F") {
			throw new Error("sex")
		}

		if (!(date instanceof Date)) {
			throw new Error("date")
		}

		var year = date.getFullYear().toString()
		year = year.slice(-2)

		var month = monthLiterals[date.getMonth()]

		var day = date.getDate()
		if (sex.toUpperCase() === 'F') day += 40

		if (day < 10) {
			day = '0' + day
		}

		return '' + year + month + day
	}

	// private method(s)
	const _constructor = () => {
		// console.log('Config is running');
	};

	_constructor();

	// output/public
	return {
		getNameCode: _getNameCode,
		getLastNameCode: _getSurnameCode,
		getDateAndSexCodes: _getDateAndSexCodes 
	};

})();