// ------------------ brand (static)
/* eslint-disable-next-line no-unused-vars */
const BRAND = (() => {
	// private var(s)

	// private method(s)
	const _constructor = () => {
		// console.log('Brand is running');
	};

	// Bind Events
	const _bindEvents = () => {
		// console.log('Bind Events Here');
	};

	_constructor();

	// output/public
	return {
		bindEvents: _bindEvents,
	};
})();
