//CodiceFiscale
/* eslint-disable-next-line no-unused-vars */

const GoogleLogin = (() => {

	const _loadGoogleLoginAPI = async (appId) => {
		return new Promise(async (resolve, reject) => {

			if (window.google && window.google.accounts) {
				_initGoogleClientID(appId);
				resolve();
			}
			else {
				const script = document.createElement('script');
				script.src = "https://accounts.google.com/gsi/client";
				script.async = true;
				script.defer = true;
				script.onload = () => {
					/* google.accounts.id.initialize({
						client_id: appId,
						callback: googleLogin,
						scope: 'profile email'
					}); */
					_initGoogleClientID(appId);

					resolve();
				}
				document.head.appendChild(script);
				const jwtScript = document.createElement('script');
				jwtScript.src = "https://cdnjs.cloudflare.com/ajax/libs/jsrsasign/8.0.20/jsrsasign-all-min.js";
				jwtScript.async = true;
				jwtScript.defer = true;
				jwtScript.crossOrigin = "anonymous";
				jwtScript.nonce = "va0Ri5aV";
				document.head.appendChild(jwtScript);
			}
		});
	}

	const _initGoogleClientID = (appId) => {
		google.accounts.id.initialize({
			client_id: appId,
			callback: window.googleLogin
		});

		google.accounts.id.renderButton(document.querySelector('.google-button'), {
			type: 'standard',
			shape: 'pill',
			width: 300
		});

		window.google_visibility = true;
		/*const googleOnLoad = $("#g_id_onload");

		if (googleOnLoad) {
			googleOnLoad.attr("data-client_id", appId);
			console.log("Google data client id set");
			return true;
		}
		else {
			console.error("Google login div not found");
		}
		return false;*/
	}

	const _googleLogin = async (appId) => {
		google.accounts.id.initialize({
			client_id: appId,
			callback: window.googleLogin
		});
		google.accounts.id.prompt();
	}

	// private method(s)
	const _constructor = () => {
		// console.log('Config is running');
	};

	_constructor();

	// output/public
	return {
		loadGoogleLoginAPI: _loadGoogleLoginAPI,
		initGoogleClientID: _initGoogleClientID,
		googleLogin: _googleLogin
	};

})();