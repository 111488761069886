//CodiceFiscale
/* eslint-disable-next-line no-unused-vars */

const FacebookLogin = (() => {

	const _fbInit = (appId, version) => {
		console.log("Facebook login API loaded");
		window.fbAsyncInit = function () {
			FB.init({
				appId: appId,
				cookie: true,
				xfbml: true,
				version: version
			});

			FB.AppEvents.logPageView();

			FB.Event.subscribe('auth.authResponseChange', window.facebookLogin);

		}

		window.facebook_visibility = true;
	}

	const _fbLoadApi = () => {
		return new Promise(async (resolve, reject) => {
	
			if (window.FB) {
				resolve();
			}
			else {
				const script = document.createElement('script');
				script.src = "https://connect.facebook.net/it_IT/sdk.js";
				script.async = true;
				script.defer = true;
				script.crossOrigin = "anonymous";
				script.nonce = "va0Ri5aV";
				script.onload = () => {
					resolve();
				}
				document.head.appendChild(script);
			}
		});
	}

	const _fbLogin = async () => {
		if (FB) {
			FB.login( window.facebookLogin, { scope: 'email,public_profile', return_scopes: true });
		} else {
			console.log("Cannot login with Facebook. API not loaded");
			return null;
		}
	}

	const _checkLoginStatus = () => {
		if (FB) {
			FB.getLoginStatus(function (response) {   // See the onlogin handler
				if (response && response.status === 'connected') {
					//TODO call setAuthToken and redirect to /it/it/customer/account
					console.log('Logged in.');
				}
			});
		} else {
			console.log("Cannot check login status. Facebook API not loaded")
		}
	}

	// private method(s)
	const _constructor = () => {
		// console.log('Config is running');
	};

	_constructor();

	// output/public
	return {
		fbLoadApi: _fbLoadApi,
		fbInit: _fbInit,
		checkLoginStatus: _checkLoginStatus,
		fbLogin: _fbLogin
	};

})();