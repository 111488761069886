//URLHelper
/* eslint-disable-next-line no-unused-vars */

const URLHelper = (() => {

	// private method(s)
	const _constructor = () => {
		// console.log('Config is running');
	};

	const _updateQueryString = (key, value, url) => {
		if (!url) url = window.location.href;
		var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
			hash;

		if (re.test(url)) {
			if (typeof value !== 'undefined' && value !== null) {
				return url.replace(re, '$1' + key + "=" + value + '$2$3');
			}
			else {
				hash = url.split('#');
				url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
				if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
					url += '#' + hash[1];
				}
				return url;
			}
		}
		else {
			if (typeof value !== 'undefined' && value !== null) {
				var separator = url.indexOf('?') !== -1 ? '&' : '?';
				hash = url.split('#');
				url = hash[0] + separator + key + '=' + value;
				if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
					url += '#' + hash[1];
				}
				return url;
			}
			else {
				return url;
			}
		}
	}

	_constructor();

	// output/public
	return {
		updateQueryString: _updateQueryString
	};
})();